import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DayPicker, DateRange } from 'react-day-picker';
import { subDays } from 'date-fns';
import Dialog from '../../../components/Dialog';
import { Button, TextInput } from '../../../components/Styled';

const FilterDateWrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    max-height: calc(100% - 10%);
    flex-direction: column;
    justify-content: space-between;
`;

const FilterDateOption = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 5px 1rem;
    border-radius: 100px;
    border: 1px solid #959598;
    cursor: pointer;

    &.active {
        background: #FFF4F5;
        border-color: var(--color-base-primary-light);
    }
`;

interface IDialogDateSelector {
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (showDialog: boolean) => void,
    // eslint-disable-next-line no-unused-vars
    onDateSelected: (date: any) => void
}

const filterDateOptions = [
    {
        title: 'Hari ini',
        value: 'today'
    },
    {
        title: 'Kemarin',
        value: 'yesterday'
    },
    {
        title: '7 Hari Terakhir',
        value: 'sevenday'
    },
    {
        title: 'Minggu ini',
        value: 'this-week'
    },
    {
        title: 'Minggu lalu',
        value: 'last-week'
    },
    {
        title: 'Bulan ini',
        value: 'this-month'
    },
    {
        title: 'Bulan lalu',
        value: 'last-month'
    },
    {
        title: 'Rentang Waktu',
        value: 'custom'
    }
];

const DialogDateSelector: React.FC<IDialogDateSelector> = (props) => {
    const { dialogHandler, onDateSelected } = props;
    const defaultSelected: DateRange = {
        from: subDays(new Date(), 7),
        to: new Date()
    };
    const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
    const [filterDate, setFilterDate] = useState('');
    const [dateFrom, setDateFrom] = useState<Date | undefined>(undefined);
    const [dateTo, setDateTo] = useState<Date | undefined>(undefined);

    useEffect(() => {
        const currentDate = new Date();
        const currentWeek = moment(currentDate).startOf('isoWeek');
        const lastWeek = moment(currentDate).subtract(1, 'week').startOf('isoWeek');

        const lastStartMonth = moment(currentDate).subtract(1, 'months').startOf('month');
        const lastEndMonth = moment(currentDate).subtract(1, 'months').endOf('month');

        const currentStartMonth = moment(currentDate).subtract(0, 'months').startOf('month');
        const currentEndMonth = moment(currentDate).subtract(0, 'months').endOf('month');

        const lastWeekStartDate = lastWeek.add(0, 'day').format('DD');
        const lastWeekEndDate = lastWeek.add(6, 'day').format('DD');
        const lastWeekMonth = lastWeek.format('MM');
        const lastWeekYear = lastWeek.format('YYYY');

        const currentWeekStartDate = currentWeek.add(0, 'day').format('DD');
        const currentWeekEndDate = currentWeek.add(6, 'day').format('DD');
        const currentWeekMonth = currentWeek.format('MM');
        const currentWeekYear = currentWeek.format('YYYY');

        const date = {
            from: new Date(),
            to: new Date()
        };

        if (filterDate === 'today') {
            date.from = currentDate;
            date.to = currentDate;
        }
        if (filterDate === 'yesterday') {
            date.from = subDays(currentDate, 1);
            date.to = subDays(currentDate, 1);
        }
        if (filterDate === 'sevenday') {
            date.from = subDays(currentDate, 7);
            date.to = currentDate;
        }
        if (filterDate === 'this-week') {
            date.from = new Date(Number(currentWeekYear), Number(currentWeekMonth) - 1, Number(currentWeekStartDate));
            date.to = new Date(Number(currentWeekYear), Number(currentWeekMonth) - 1, Number(currentWeekEndDate));
        }
        if (filterDate === 'last-week') {
            date.from = new Date(Number(lastWeekYear), Number(lastWeekMonth) - 1, Number(lastWeekStartDate));
            date.to = new Date(Number(lastWeekYear), Number(lastWeekMonth) - 1, Number(lastWeekEndDate));
        }
        if (filterDate === 'this-month') {
            date.from = new Date(Number(currentStartMonth.format('YYYY')), Number(currentStartMonth.format('MM')) - 1, Number(currentStartMonth.format('DD')));
            date.to = new Date(Number(currentEndMonth.format('YYYY')), Number(currentEndMonth.format('MM')) - 1, Number(currentEndMonth.format('DD')));
        }
        if (filterDate === 'last-month') {
            date.from = new Date(Number(lastStartMonth.format('YYYY')), Number(lastStartMonth.format('MM')) - 1, Number(lastStartMonth.format('DD')));
            date.to = new Date(Number(lastEndMonth.format('YYYY')), Number(lastEndMonth.format('MM')) - 1, Number(lastEndMonth.format('DD')));
        }
        if (filterDate === 'custom') {
            setDateFrom(range?.from);
            setDateTo(range?.to);
        }
        setDateFrom(date.from);
        setDateTo(date.to);
        setRange(date);
    }, [filterDate]);

    return (
        <Dialog dialogHandler={dialogHandler} fullWidth>
            <div className="row">
                <div className="col-9">
                    <div className="row">
                        <div className="col-6">
                            <TextInput border block style={{ height: 34 }} readOnly className="mb-4" value={dateFrom ? moment(new Date(dateFrom)).format('yyyy-MM-DD') : 'YYYY-MM-DD'} />
                        </div>
                        <div className="col-6">
                            <TextInput border block style={{ height: 34 }} readOnly className="mb-4" value={dateTo ? moment(new Date(dateTo)).format('yyyy-MM-DD') : 'YYYY-MM-DD'} />
                        </div>
                    </div>
                    <DayPicker mode="range" numberOfMonths={2} selected={range} onSelect={setRange} />
                </div>
                <div className="col-3">
                    <FilterDateWrapper>
                        {filterDateOptions.map((item: any, idx: any) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <FilterDateOption key={idx} className={`${filterDate === item.value ? 'active' : ''}`} onClick={() => setFilterDate(item.value)}>{item.title}</FilterDateOption>
                        ))}
                    </FilterDateWrapper>
                </div>
                <div className="col-12 mt-2">
                    <div className="row">
                        <div className="col-6" />
                        <div className="col-3">
                            <Button block rounded border textBlack primary onClick={() => dialogHandler(false)}>Batal</Button>
                        </div>
                        <div className="col-3">
                            <Button block rounded gradient onClick={() => { onDateSelected(range); dialogHandler(false); }}>Pilih</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogDateSelector;
