import React from 'react';
import { Card } from '../../components/Styled';
import DashboardLayout from '../../layout/DashboardLayout';
import iconWhatsapp from '../../assets/icon/icon-whatsapp.svg';
import iconEmail from '../../assets/icon/icon-email-send.svg';
import iconCall from '../../assets/icon/icon-call.svg';
import Icon from '../../components/Icon';

class Help extends React.Component {
    render(): React.ReactNode {
        return (
            <DashboardLayout activeMenu="bantuan">
                <div className="container-fluid p-3 p-md-4">
                    <div className="row my-3">
                        <div className="col-9">
                            <h5 className="font-bold">Kanggo siap membantu kamu</h5>
                            <p className="m-0">Jam operasional kami</p>
                            <ul>
                                <li>
                                    Senin-Minggu
                                    <strong> 08:00 s/d 20:00</strong>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4">
                            <Card border>
                                <div className="row align-items-center">
                                    <div className="col-profile">
                                        <Icon custom icon={iconWhatsapp} />
                                    </div>
                                    <div className="col-8">
                                        <p className="mb-1 font-bold">WhatsApp</p>
                                        <span>08119733557</span>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-12 col-md-4">
                            <Card border>
                                <div className="row align-items-center">
                                    <div className="col-profile">
                                        <Icon custom icon={iconEmail} />
                                    </div>
                                    <div className="col-8">
                                        <p className="mb-1 font-bold">Email</p>
                                        <span>cs@kanggo.id</span>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-12 col-md-4">
                            <Card border>
                                <div className="row align-items-center">
                                    <div className="col-profile">
                                        <Icon custom icon={iconCall} />
                                    </div>
                                    <div className="col-8">
                                        <p className="mb-1 font-bold">Hubungi Kanggo</p>
                                        <span>021-50112100</span>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default Help;
