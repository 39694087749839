import React, { useEffect, useState } from 'react';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import styled from 'styled-components';
import useCurrency from '../../../helpers/useCurrency';
import API from '../../../configs/api';
import TabLink from '../components/TabLink';
import NoData from '../components/NoData';
import { Badge, Button } from '../../../components/Styled';
import noImage from '../../../assets/img/no-image.png';
import DrawerPaymentSuccess from '../components/DrawerPaymentSuccess';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';

const ListPaymentTable = styled.div`
    position: relative;
    display: block;
    width: 100%;
    border-radius: 10px;

    .rdt_Table {
        padding: 0 10px 120px;

        .rdt_TableHeadRow {
            border: none !important;
        }

        .rdt_TableRow {
            border: none !important;
            box-shadow: 0px 0 10px 2px rgba(146, 146, 146, 0.2);
            border-radius: 3px 3px 0 0;
            &:first-of-type {
                margin-top: 15px;
            }
        }

        .rdt_ExpanderRow {
            position: relative;
            padding: 10px 1rem;
            box-shadow: 0px 5px 10px 0px rgba(146, 146, 146, 0.2);
            margin-bottom: 15px;
            border-radius: 0 0 3px 3px;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                left: 50%;
                top: 0;
                background-image: linear-gradient(to right,#D8CFCD 50%,rgba(255,255,255,0) 0%);
                background-position: bottom;
                background-size: 10px 2px;
                background-repeat: repeat-x;
                transform: translateX(-50%);
            }
        }
    }
`;

const CustomRow = styled.div`
    position: relative;
    display: block;
    width: 100%;
`;

interface IExpandComponent {
    total_mandor: number,
    external_id: string,
    approved_mandors: any[]
}

const ExpandComponent: React.FC<ExpanderComponentProps<IExpandComponent>> = (props) => {
    const { data } = props;
    const dispatch = useAppDispatch();
    const [mandors, setMandors] = useState<any>([]);

    const onDetailClick = () => {
        dispatch(action.drawerDetail(data, true));
    };

    useEffect(() => {
        if (data.approved_mandors.length > 0) {
            setMandors(data.approved_mandors.slice(0, 4));
        }
    }, [data]);

    return (
        <div className="row align-items-center">
            <div className="col-8">
                <Badge success rounded className="">
                    <div className="row align-items-center justify-content-between">
                        <div className={`col-profile ${data.total_mandor > 1 ? 'stacking' : ''}`} style={{ padding: 0, marginRight: `-${(mandors.length > 3 ? mandors.length + 1 : mandors.length) * 10}px` }}>
                            {mandors.map((item: any, idx: any) => (
                                <div key={item.mandor_id} className="profile-img" style={{ left: `${idx !== 0 ? `-${idx * 10}` : '0'}px` }}>
                                    {item.profile_image && (
                                        <img style={{ border: 'none' }} height={35} width={35} src={item.profile_image} alt={item.mandor_name} />
                                    )}
                                    {!item.profile_image && (
                                        <img style={{ border: 'none' }} src={noImage} alt={item.mandor_name} />
                                    )}
                                </div>
                            ))}
                            {data.approved_mandors.length > 5 && (
                                <div className="profile-img" style={{ left: `-${mandors.length * 10}px` }}>
                                    <img style={{ border: 'none' }} src={data.approved_mandors[mandors.length].profile_image ? data.approved_mandors[mandors.length].profile_image : noImage} alt="5" />
                                    <div className="more-profile">
                                        <span>{`+${data.total_mandor - mandors.length - 1}`}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col">
                            <p className="ms-1 mb-0 text-black">
                                Pembayaran
                                <span className="font-bold">{` ${data.total_mandor} Mandor berhasil ditransfer`}</span>
                            </p>
                        </div>
                    </div>
                </Badge>
            </div>
            <div className="col-4 text-end">
                <Button onClick={onDetailClick} border rounded primary>Lihat Detail</Button>
            </div>
        </div>
    );
};

const PaymentSuccess: React.FC = () => {
    const { drawerDetail, drawerDetailData } = useAppSelector((state: any) => state.uiReducer);
    const [isPending, setPending] = useState(true);
    const [totalRow, setTotalRow] = useState(0);
    const [data, setData] = useState([]);
    const [, setSelectedRows] = useState<any>([]);
    const [showDrawerPayment, setShowDrawerPayment] = useState(false);

    const [limitRows, setLimitRows] = useState(10);
    const [perPage, setPerPage] = useState(1);

    const fetchData = async (page: number, limit: number) => {
        const payload = {
            query: { page, limit }
        };
        await API.paymentStatusSuccess(payload).then((result: any) => {
            setTotalRow(result.total_data);
            setData(result.data);
            setPending(false);
        });
    };

    const handlePageChange = (page: any) => {
        setPerPage(page);
    };

    const handlePerRowsChange = (limit: any) => {
        setLimitRows(limit);
    };

    // const handleRowDelete = () => {
    //     // setToggleCleared(!toggleCleared);
    //     setSelectedRows([]);
    // };

    useEffect(() => {
        fetchData(perPage, limitRows);
    }, [perPage, limitRows]);

    useEffect(() => {
        if (drawerDetail) {
            setShowDrawerPayment(drawerDetailData);
        } else {
            setSelectedRows([]);
        }
    }, [drawerDetailData]);

    // ** Table Payment List Column
    const columns = [
        {
            name: 'Tgl & Waktu',
            sortable: true,
            minWidth: '80px',
            selector: (row: any) => row.created_at
        },
        {
            name: 'Nama Proyek',
            sortable: true,
            minWidth: '130px',
            selector: (row: any) => (row.projects.length > 1 ? 'Multi Proyek' : row.projects[0]?.project_name),
            conditionalCellStyles: [
                {
                    when: (row: any) => row.projects.length > 0,
                    // style: (row: any) => ({ color: row.project_name === 'Multi Proyek' ? '#EB4600' : 'rgba(0,0,0,0.87);' })
                    style: {
                        color: '#EB4600'
                    }
                }
            ]
        },
        {
            name: 'Client',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => (row.projects.length > 1 ? 'Multi Client' : row.projects[0]?.client_name),
            conditionalCellStyles: [
                {
                    when: (row: any) => row.projects.length > 0,
                    // style: (row: any) => ({ color: row.project_name === 'Multi Proyek' ? '#EB4600' : 'rgba(0,0,0,0.87);' })
                    style: {
                        color: '#EB4600'
                    }
                }
            ]
        },
        {
            name: 'Nama PM',
            sortable: true,
            width: '130px',
            selector: (row: any) => (row.projects.length > 1 ? <CustomRow onClick={() => console.log(row)}>Multi PM</CustomRow> : row.projects[0]?.pm_name),
            conditionalCellStyles: [
                {
                    when: (row: any) => row.projects.length > 0,
                    // style: (row: any) => ({ color: row.project_name === 'Multi Proyek' ? '#EB4600' : 'rgba(0,0,0,0.87);' })
                    style: {
                        color: '#EB4600'
                    }
                }
            ]
        },
        {
            name: 'Anggota',
            sortable: true,
            minWidth: '30px',
            maxWidth: '130px',
            selector: (row: any) => row.total_mandor
        },
        {
            name: 'Total Pembayaran',
            sortable: true,
            minWidth: '120px',
            style: {
                fontWeight: 600
            },
            selector: (row: any) => `Rp. ${useCurrency(parseInt(row.total_pembayaran, 10))}`
        }
    ];

    return (
        <>
            <TabLink tabActive="selesai" />
            <div className="container-fluid px-3 py-2 px-md-4 py-md-3">
                <ListPaymentTable>
                    {!isPending && data.length > 0 && (
                        <DataTable
                            noHeader
                            columns={columns}
                            expandableRows
                            expandableRowsHideExpander
                            expandableRowExpanded={() => true}
                            expandableRowsComponent={ExpandComponent}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRow}
                            paginationPerPage={10}
                            progressPending={isPending}
                            onChangePage={(page: any) => {
                                handlePageChange(page);
                            }}
                            onChangeRowsPerPage={(limit: any) => {
                                handlePerRowsChange(limit);
                            }}
                            data={data}
                        />
                    )}
                    {!isPending && data.length < 1 && (
                        <NoData message="Tidak ada data transaksi selesai" />
                    )}
                </ListPaymentTable>
            </div>
            {showDrawerPayment && (
                // <DrawerPaymentSuccess data={selectedRows} drawerHandler={(showDrawer: boolean) => setShowDrawerPayment(showDrawer)} />
                <DrawerPaymentSuccess data={drawerDetailData} drawerHandler={(showDrawer: boolean) => setShowDrawerPayment(showDrawer)} />
            )}
        </>
    );
};

export default PaymentSuccess;
