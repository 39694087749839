import React, { useState, useEffect } from 'react';
import API from '../../../configs/api';
import BlacklistItem from '../components/BlacklistItem';
import TabLink from '../components/TabLink';

const HistoryBlacklist: React.FC = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        await API.blacklist().then((result: any) => {
            setData(result.data);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <TabLink tabActive="riwayat" />
            <div className="container-fluid">
                {data.map((item: any) => (
                    <BlacklistItem key={item.id} data={item} />
                ))}
            </div>
        </>
    );
};

export default HistoryBlacklist;
