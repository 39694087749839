import React from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import LoadingOverlay from '../../components/LoadingOverlay';
import LogoutDialog from '../../components/LogoutDialog';
import { useAppSelector, useAppDispatch } from '../../hooks/useAppReducer';
import useDate from '../../helpers/useDate';
import Toast from '../../components/Toast';
import DrawerProfile from '../../components/DrawerProfile';
import action from '../../configs/redux/action';

const DashboardLayoutWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
`;

const BreadcrumbWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
`;

const DateTimeWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    text-align: right;
    padding: 6px 1rem;
    color: var(--color-gray);
`;

const SnackbarWrapper = styled.div`
    position: sticky;
    display: block;
    width: 100%;
    height: auto;
    color: var(--color-white);
    background: #f4f4f4;
    padding: 10px 1rem;
    top: 60px;
    z-index: 10;
`;

interface IDashboardLayout {
    activeMenu?: string,
    breadcrumbs?: any[],
    children: any
}

const DashboardLayout = (props: IDashboardLayout) => {
    const { activeMenu, breadcrumbs, children } = props;
    const dispatch = useAppDispatch();
    const { toast, toastType, toastMessage, loading, logoutDialog, drawerProfile, drawerProfileUserId, pendingPaymentSnackbar } = useAppSelector((state) => state.uiReducer);

    return (
        <DashboardLayoutWrapper>
            <Sidebar activeMenu={activeMenu} />
            <div id="dashboard-main">
                <Header activeMenu={activeMenu} />
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between" style={{ background: '#f4f4f4' }}>
                        <div className="col-6">
                            {breadcrumbs && breadcrumbs?.length > 0 && (
                                <BreadcrumbWrapper>
                                    Halaman &gt; Halaman Lagi
                                </BreadcrumbWrapper>
                            )}
                        </div>
                        <div className="col-6">
                            <DateTimeWrapper>
                                <div className="container-fluid">
                                    <span>{`${useDate.getDay()}, ${useDate.getCurrentDate(true, true, true)} | ${useDate.getCurrentTime(true, true)}`}</span>
                                </div>
                            </DateTimeWrapper>
                        </div>
                    </div>
                </div>
                {pendingPaymentSnackbar && (
                    <SnackbarWrapper>
                        Hay
                    </SnackbarWrapper>
                )}
                {children}
            </div>
            {toast && (
                <Toast toastType={toastType} message={toastMessage} right />
            )}
            {logoutDialog && (
                <LogoutDialog />
            )}
            {loading && (
                <LoadingOverlay />
            )}
            {drawerProfile && (
                <DrawerProfile userId={drawerProfileUserId} drawerHandler={(showDrawer: boolean) => dispatch(action.drawerProfile('', showDrawer))} />
            )}
        </DashboardLayoutWrapper>
    );
};

DashboardLayout.defaultProps = {
    activeMenu: '',
    breadcrumbs: []
};

export default DashboardLayout;
