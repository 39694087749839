/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const PaginationWrapper = styled.nav`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;

    .paginate {
        position: relative;
        height: 36px;
        width: 100%;
        margin: 0;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        flex-direction: row;
        list-style-type: none;

        .paginate-next,
        .paginate-prev,
        .paginate-page {
            position: relative;
            display: inline-flex;
            height: 30px;
            width: 30px;
            align-items: center;
            justify-content: center;
            background: transparent;
            color: #D9272D;
            border: 1px solid #bebebe;
            border-radius: 4px;

            &.active {
                border: 1px solid #B52126;

                a {
                    color: #D9272D;
                    outline: none;
                    font-weight: bold;
                }
            }

            a {
                outline: none;
                color: #212B36;
                text-decoration: none;
                width: 100%;
                text-align: center;
            }
        }
        .paginate-page {
            a {
                color: #2F2F2F;
            }
        }

        .disabled-arrow {
            background-color: #D8CFCD;
            font-weight: bold;
            opacity: 0.5;
        }
    }
`;

const ShowingWrapper = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;

    p {
        margin-bottom: 0;
    }
`;

interface IPagination {
    totalData: number,
    currentPage: number,
    pageCount: number,
    limitPage?: number,
    // eslint-disable-next-line no-unused-vars
    onPageChange: (event: any) => void
}

const Pagination: React.FC<IPagination> = (props) => {
    const { totalData, currentPage, pageCount, limitPage, onPageChange } = props;

    return (
        <PaginationWrapper>
            <ShowingWrapper>
                <p> Menampilkan </p>
                <p className="mx-1 fw-bold">
                    {currentPage}
                    -
                    {pageCount}
                </p>
                <p> dari </p>
                <p className="mx-1 fw-bold">{totalData}</p>
                <p> data</p>
            </ShowingWrapper>
            <ReactPaginate
                className="paginate"
                pageClassName="paginate-page"
                activeClassName="active"
                breakLabel="..."
                nextClassName="paginate-next"
                nextLabel=">"
                previousLabel="<"
                previousClassName="paginate-prev"
                disabledClassName="disabled-arrow"
                onPageChange={(event: any) => {
                    onPageChange(event);
                }}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                forcePage={currentPage && currentPage !== 0 ? currentPage - 1 : 0}
            />
        </PaginationWrapper>
    );
};

Pagination.defaultProps = {
    limitPage: 10
};

export default Pagination;
