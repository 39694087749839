import React, { useEffect, useState } from 'react';
import API from '../../../configs/api';
import BlacklistItem from '../components/BlacklistItem';
import TabLink from '../components/TabLink';
import Pagination from '../../../components/Pagination';

const ListBlacklist: React.FC = () => {
    const [data, setData] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = data.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(data.length / itemsPerPage);

    const onPageChange = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setPage(event.selected + 1);
        setItemOffset(newOffset);
    };

    const fetchData = async () => {
        await API.blacklist().then((result: any) => {
            setData(result.data);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <TabLink tabActive="list" />
            <div className="container-fluid">
                {currentItems.map((item: any) => (
                    <BlacklistItem key={item.id} data={item} />
                ))}
                <Pagination
                    totalData={data.length}
                    currentPage={page}
                    pageCount={pageCount}
                    onPageChange={(event: any) => {
                        onPageChange(event);
                    }}
                />
            </div>
        </>
    );
};

export default ListBlacklist;
