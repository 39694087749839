/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import DashboardLayout from '../../layout/DashboardLayout';
import { Button, Label, TextInput, TextInputGroup } from '../../components/Styled';
import banner from '../../assets/img/banner-kanggo.png';
import iconEyeClose from '../../assets/icon/icon-eye-close.svg';
import iconEyeOpen from '../../assets/icon/icon-eye-open.svg';
import Icon from '../../components/Icon';
import action from '../../configs/redux/action';
import API from '../../configs/api';
import SendEmailDialog from '../../components/SendEmailDialog';
import BackButton from '../../components/BackButton';

const PasswordWrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    #banner{
        height: 100%;
        img {
            height: 100%;
        }
        
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .action-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;

        @media only screen and (max-width: 768px) {
            position: relative;
        }
    }
`;

interface IPasswordState {
    old_password: string,
    new_password: string,
    confirm_password: string,
    show_old_password: boolean,
    show_new_password: boolean,
    show_confirm_password: boolean,
    forgot_password: boolean
}

interface IPassword {
    dispatch: any,
    email: string
}

class Password extends React.Component<IPassword, IPasswordState> {
    constructor(props: IPassword) {
        super(props);
        this.state = {
            old_password: '',
            new_password: '',
            confirm_password: '',
            show_old_password: false,
            show_new_password: false,
            show_confirm_password: false,
            forgot_password: false
        };
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput = (event: { target: { id: string, value: any } }) => {
        const newState = { [event.target.id]: event.target.value } as Pick<IPasswordState, keyof IPasswordState>;
        this.setState(newState);
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { old_password, new_password } = this.state;
        dispatch(action.showLoading());
        const payload = {
            body: {
                old_password,
                new_password
            }
        };
        API.changePassword(payload).then((response: any) => {
            dispatch(action.hideLoading());
            dispatch(action.showToast('success', response.message));
        }).catch((err) => {
            dispatch(action.hideLoading());
            dispatch(action.showToast('error', err.message));
        });
    };

    render(): React.ReactNode {
        const { email } = this.props;
        const { old_password, new_password, confirm_password, show_old_password, show_new_password, show_confirm_password, forgot_password } = this.state;
        return (
            <DashboardLayout activeMenu="password">
                <PasswordWrapper>
                    <div id="banner">
                        <img src={banner} alt="PT Tenaga Kanggo Indonesia" />
                    </div>
                    <div className="container-fluid p-3 p-md-4">
                        <div className="row mb-3">
                            <div className="col-12">
                                <h5 className="font-bold">Ubah Password</h5>
                                <p>Silakan masukkan Password saat ini dan masukkan Password baru kamu</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <Label bold htmlFor="old_password">Masukkan Password Lama</Label>
                                <TextInputGroup style={{ marginBottom: 30 }} border>
                                    <TextInput block border id="old_password" type={show_old_password ? 'text' : 'password'} placeholder="********" onChange={this.handleInput} value={old_password} />
                                    <div id="input-icon" role="button" onClick={() => this.setState({ show_old_password: !show_old_password })}>
                                        <div style={{ display: show_old_password ? 'none' : 'block' }}>
                                            <Icon custom icon={iconEyeClose} />
                                        </div>
                                        <div style={{ display: show_old_password ? 'block' : 'none' }}>
                                            <Icon custom icon={iconEyeOpen} />
                                        </div>
                                    </div>
                                </TextInputGroup>
                                <Label bold htmlFor="new_password">Masukkan Password Baru</Label>
                                <TextInputGroup style={{ marginBottom: 30 }} border>
                                    <TextInput block border id="new_password" type={show_new_password ? 'text' : 'password'} placeholder="********" onChange={this.handleInput} value={new_password} />
                                    <div id="input-icon" role="button" onClick={() => this.setState({ show_new_password: !show_new_password })}>
                                        <div style={{ display: show_new_password ? 'none' : 'block' }}>
                                            <Icon custom icon={iconEyeClose} />
                                        </div>
                                        <div style={{ display: show_new_password ? 'block' : 'none' }}>
                                            <Icon custom icon={iconEyeOpen} />
                                        </div>
                                    </div>
                                </TextInputGroup>
                                <Label bold htmlFor="confirm_password">Konfirmasi Password Baru</Label>
                                <TextInputGroup style={{ marginBottom: 30 }} border>
                                    <TextInput block border id="confirm_password" type={show_confirm_password ? 'text' : 'password'} placeholder="********" onChange={this.handleInput} value={confirm_password} />
                                    <div id="input-icon" role="button" onClick={() => this.setState({ show_confirm_password: !show_confirm_password })}>
                                        <div style={{ display: show_confirm_password ? 'none' : 'block' }}>
                                            <Icon custom icon={iconEyeClose} />
                                        </div>
                                        <div style={{ display: show_confirm_password ? 'block' : 'none' }}>
                                            <Icon custom icon={iconEyeOpen} />
                                        </div>
                                    </div>
                                </TextInputGroup>
                            </div>
                            <div className="col-12">
                                <div onClick={() => this.setState({ forgot_password: true })}>
                                    <span className="text-primary font-bold">Lupa Password?</span>
                                </div>
                            </div>
                        </div>
                        <div className="action-wrapper p-3 p-md-4">
                            <div className="row">
                                <div className="col-4 col-md-8" />
                                <div className="col-4 col-md-2">
                                    <BackButton />
                                </div>
                                <div className="col-4 col-md-2">
                                    <Button block rounded gradient onClick={this.handleSubmit}>Simpan</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </PasswordWrapper>
                {forgot_password && (
                    <SendEmailDialog message={`Link perubahan Password sudah dikirim ke email ${email}. Silakan lakukan perubahan Password`} dialogHandler={(showDialog: boolean) => this.setState({ forgot_password: showDialog })} />
                )}
            </DashboardLayout>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        email: state.userReducer.profile.email
    };
};

export default connect(mapStateToProps)(Password);
