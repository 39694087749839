import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import Drawer from '../../../components/Drawer';

import Icon from '../../../components/Icon';
import iconPeopleTeamAdd from '../../../assets/icon/icon-people-team-add.svg';
import iconWallet from '../../../assets/icon/icon-wallet.svg';
import noImage from '../../../assets/img/no-image.png';
import useCurrency from '../../../helpers/useCurrency';
import useDate from '../../../helpers/useDate';
import { Badge, Button, Card, TabContent, TabItem, TabLayout } from '../../../components/Styled';
import API from '../../../configs/api';
import NoData from '../../../components/NoData';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';
import DialogShutdownWorker from './DialogShutdownWorker';
import BadgeStatus from './BadgeStatus';

const ProjectDetail = styled.div`
    position: relative;
    width: 100%;
    border-radius: 20px;
    background: #4E4E4E;
    margin: 25px 0;

    .item-project-header {
        position: relative;
        padding: 10px 20px;
        background: #2F2F2F;
        color: #FFF;
        border-radius: 20px 20px 0 0;
    }

    .item-project-body {
        position: relative;
        display: block;
        width: 100%;
        padding: 20px;

        .item-project-detail {
            position: relative;
            display: block;
            width: 100%;
            padding: 15px;
            background: var(--color-white);
            border-radius: 20px;

            img {
                filter: grayscale(100%);
                opacity: 70%;
            }
        }
    }
    
    .item-project-footer {
        position: relative;
        display: block;
        background: #686868;
        width: 100%;
        margin: 20px 0 0;
        padding: 15px 20px;
        color: #FFF;
        border-radius: 20px;

        .profile-img {
            width: 40px;
            height: 40px;
            margin: auto;

            img {
                height: 100%;
                border-radius: 100px;
            }
        }
    }
`;

const DrawerHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    top: 0;
    padding: .75rem 1.5rem;
    background: var(--color-white);
    z-index: 1;

    button {
        background: #F7F5F5;
        border-color: #D8CFCD;
    }
`;

const DrawerBody = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: .75rem 1.5rem;

    #item {
        position: relative;
        padding: 0 1.5rem;
    }
`;

const ItemMemberDetail = styled.div`
    position: relative;
    display: block;
    width: 100%;
    margin: 25px 0;

    .profile-img {
        width: 40px;
        height: 40px;
        margin: auto;

        img {
            height: 100%;
            border-radius: 100px;
        }
    }
`;

const ItemHistory = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    box-shadow: 0px 4px 16px rgba(146, 146, 146, 0.25);
    border-radius: 20px;

    .header-history {
        position: relative;
        display: block;
        background: #2F2F2F;
        border-radius: 20px 20px 0 0;
        padding: 10px 1rem;
        color: #fff;
    }

    .content-history {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        padding: 20px 1rem;

        .rdt_Table {
            margin-top: 20px;
            padding: 0 5px 0;
        }
    }
`;

interface IDrawerDetailProject {
    project: any,
    // eslint-disable-next-line no-unused-vars
    drawerHandler: (showDrawer: boolean) => void
}

const DrawerDetailProject: React.FC<IDrawerDetailProject> = (props) => {
    const { project, drawerHandler } = props;
    const dispatch = useAppDispatch();
    const [data, setData] = useState<any>({});
    const [activeTab, setActiveTab] = useState('member');
    const [listMember, setListMember] = useState([]);
    const [listHistory, setListHistory] = useState([]);
    const [shutdownDialog, setShutDownDialog] = useState(false);

    const fetchData = () => {
        const payload = {
            params: `/${project.id}/detail`
        };
        API.projectContractor(payload).then((result: any) => {
            setData(result.data.project);
        });
    };

    const fetchMember = () => {
        const payload = {
            params: `/${project.id}/detail/member`
        };
        API.projectContractor(payload).then((result: any) => {
            setListMember(result.data);
        });
    };

    const fetchHistory = () => {
        const payload = {
            params: `/${project.id}/detail/payment-history`
        };
        API.projectContractor(payload).then((result: any) => {
            setListHistory(result.data);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (activeTab === 'member') {
            fetchMember();
            return;
        }
        fetchHistory();
    }, [activeTab]);

    // ** Table Payment List Column
    const columns = [
        {
            name: 'Nama',
            sortable: true,
            minWidth: '135px',
            selector: (row: any) => row.name,
            style: {
                color: '#EB4600'
            }
        },
        {
            name: 'ID Anggota',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.user_id
        },
        {
            name: 'Posisi',
            sortable: true,
            minWidth: '170px',
            selector: (row: any) => row.role
        },
        {
            name: 'Nominal',
            sortable: true,
            minWidth: '160px',
            style: {
                fontWeight: 600
            },
            selector: (row: any) => `Rp. ${useCurrency(parseInt(row.amount, 10))}`
        },
        {
            name: 'Tipe Pembayaran',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.label
        },
        {
            name: 'Status',
            sortable: true,
            minWidth: '130px',
            selector: (row: any) => <Badge success={row.finance_confirmation === 'SUCCESS'} error={row.finance_confirmation === 'REJECT'} rounded>{row.finance_confirmation}</Badge>
        }
    ];

    const onDetailClick = (id: any) => {
        dispatch(action.drawerProfile(id, true));
    };

    const onDeleteConfirm = (id: string) => {
        dispatch(action.showLoading());
        const payload = {
            params: `/${id}/shutdown`
        };
        API.shutdownMember(payload).then(() => {
            fetchMember();
            setTimeout(() => {
                dispatch(action.hideLoading());
                dispatch(action.showToast('success', 'Berhasil dikeluarkan dari proyek'));
            }, 1000);
        }).catch((err) => {
            fetchMember();
            setTimeout(() => {
                dispatch(action.hideLoading());
                dispatch(action.showToast('error', err.message));
            }, 1000);
        });
    };

    return (
        <Drawer noPadding right drawerHandler={drawerHandler}>
            <DrawerHeader>
                <span className="d-block mb-2">Status</span>
                <BadgeStatus status_project={project.status_project} />
                {/* <div className="row align-items-center">
                    <div className="col-8">
                    </div>
                    <div className="col-3 text-end">
                        <Button border rounded textBlack onClick={() => setShowDeleteDialog(true)}>Hapus Semua Transaksi</Button>
                    </div>
                    <div className="col-1 text-end">
                        <ButtonIcon onClick={() => setStaticClose(true)}>
                            <Icon icon="x" />
                        </ButtonIcon>
                    </div>
                </div> */}
            </DrawerHeader>
            <DrawerBody>
                <ProjectDetail>
                    <div className="item-project-header">
                        <div className="row">
                            <div className="col-6">
                                <span>{`${useDate.getDay()}, ${useDate.getCurrentDate(true, true, true)}`}</span>
                            </div>
                            <div className="col-6 text-end">
                                <span>{data.invoice}</span>
                            </div>
                        </div>
                    </div>
                    <div className="item-project-body">
                        <div className="item-project-detail">
                            <div id="project-title">
                                <span className="font-large font-bold">{data.project_code}</span>
                                <span className="font-large"> | </span>
                                <span className="font-large">{data.project_name}</span>
                                <p>{data.client_name}</p>
                            </div>
                            <div id="project-detail">
                                <p className="m-0">{data.address}</p>
                                <p className="m-0">
                                    <span style={{ color: '#A5A5A6' }}>Catatan : </span>
                                    <span className="fst-italic" style={{ color: '#2F2F2F' }}>{data.note}</span>
                                </p>
                            </div>
                            <div className="lines my-2" />
                            <div className="row">
                                <div className="col-6 border-end">
                                    <p className="mb-1">Anggota yang diajukan</p>
                                    <div>
                                        <Icon custom icon={iconPeopleTeamAdd} />
                                        <span className="px-2 align-middle font-bold font-large">{data.total_workers}</span>
                                    </div>
                                </div>
                                <div className="col-6 ps-5">
                                    <p className="mb-1">Total Pengajuan</p>
                                    <div>
                                        <Icon custom icon={iconWallet} />
                                        <span className="px-2 align-middle font-bold font-large">{`Rp${useCurrency(data.total_request)}`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-project-footer">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row my-2 justify-content-between">
                                        <div className="col-profile">
                                            <div className="profile-img">
                                                {data.pm_image && (
                                                    <img src={data.pm_image} alt={data.pm_name} />
                                                )}
                                                {!data.pm_image && (
                                                    <img src={noImage} alt={data.pm_name} />
                                                )}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <p className="mb-1">Project Manager (PM)</p>
                                            <p className="mb-1 font-bold">{data.pm_name}</p>
                                            <p className="m-0">{data.pm_id}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 text-end">
                                    <Button rounded gradient onClick={() => onDetailClick(data.pm_id)}>Lihat Profil</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ProjectDetail>
                <TabLayout sticky top={0}>
                    <TabItem onClick={() => setActiveTab('member')} className={`${activeTab === 'member' ? 'active' : ''}`}>Anggota</TabItem>
                    <TabItem onClick={() => setActiveTab('history')} className={`${activeTab === 'history' ? 'active' : ''}`}>Riwayat Pembayaran</TabItem>
                </TabLayout>
                <TabContent>
                    {activeTab === 'member' && (
                        <>
                            {listMember && listMember.length > 0 && listMember.map((item: any) => (
                                <ItemMemberDetail key={item.id}>
                                    <Card shadow>
                                        <div className="row justify-content-between">
                                            <div className="col-8">
                                                <div className="row my-2 justify-content-between">
                                                    <div className="col-profile">
                                                        <div className="profile-img">
                                                            {item.profile_image || item.pm_profile_image ? (
                                                                <img src={item.profile_image ? item.profile_image : item.pm_profile_image} alt={item.pm_name ? item.pm_name : item.name} />
                                                            ) : (
                                                                <img src={noImage} alt="Kanggo" />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <p className="mb-1 font-bold">
                                                            <span>{item.name}</span>
                                                        </p>
                                                        <p className="mb-1">{item.user_id}</p>
                                                        <p className="mb-0" style={{ color: '#959598' }}>{item.role}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Button block rounded border primary onClick={() => setShutDownDialog(true)}>Keluarkan</Button>
                                                    </div>
                                                    <div className="col-6">
                                                        <Button block rounded gradient onClick={() => onDetailClick(item.user_id)}>Lihat Profile</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    {shutdownDialog && (
                                        <DialogShutdownWorker dialogHandler={(visible: boolean) => setShutDownDialog(visible)} onDeleteConfirm={() => onDeleteConfirm(item.user_id)} member={item.name} />
                                    )}
                                </ItemMemberDetail>
                            ))}
                            {listMember.length < 1 && (
                                <NoData message="Tidak ada data mandor" />
                            )}
                        </>
                    )}
                    {activeTab === 'history' && (
                        <>
                            {listHistory && listHistory.length > 0 && listHistory.map((item: any) => (
                                <ItemHistory key={item.id}>
                                    <div className="header-history">
                                        <div className="row">
                                            <div className="col-6">
                                                <span>{`${useDate.getDay()}, ${useDate.getCurrentDate(true, true, true)}`}</span>
                                            </div>
                                            <div className="col-6 text-end">
                                                <span>{item.invoice}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-history">
                                        <div className="row">
                                            <div className="col-4 border-end">
                                                <p className="mb-1">Anggota yang diajukan</p>
                                                <div>
                                                    <Icon custom icon={iconPeopleTeamAdd} />
                                                    <span className="px-2 align-middle font-bold font-large">{item.total_worker}</span>
                                                </div>
                                            </div>
                                            <div className="col-4 border-end ps-5">
                                                <p className="mb-1">Total Pengajuan</p>
                                                <div>
                                                    <Icon custom icon={iconWallet} />
                                                    <span className="px-2 align-middle font-bold font-large">{`Rp${useCurrency(item.total_amount_request)}`}</span>
                                                </div>
                                            </div>
                                            <div className="col-4 ps-5">
                                                <p className="mb-1">Total Pembayaran</p>
                                                <div>
                                                    <Icon custom icon={iconWallet} />
                                                    <span className="px-2 align-middle font-bold font-large">{`Rp${useCurrency(item.total_amount_request)}`}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <DataTable
                                            noHeader
                                            columns={columns}
                                            data={item.worker}
                                            highlightOnHover
                                        />
                                    </div>
                                </ItemHistory>
                            ))}
                            {listHistory && listHistory.length < 1 && (
                                // <div>Oke</div>
                                <NoData message="Tidak ada riwayat pembayaran" />
                            )}
                        </>
                    )}
                </TabContent>
            </DrawerBody>
        </Drawer>
    );
};

export default DrawerDetailProject;
