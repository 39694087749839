import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Styled';

const BlacklistItemWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 5px 1rem 10px;
    margin: 20px 0 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 16px rgba(146, 146, 146, 0.25);
`;

const BlacklistItemHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 5px 0px 10px;
    border-bottom: 2px dashed #959598;
`;

const BlacklistItemBody = styled.div`
    position: relative;
    padding: 15px 0 0;
`;

interface IBlacklistItem {
    data: any
}

const BlacklistItem: React.FC<IBlacklistItem> = (props) => {
    const { data } = props;
    return (
        <BlacklistItemWrapper>
            <BlacklistItemHeader>
                <div className="row">
                    <div className="col-6">{data.created_at}</div>
                    <div className="col-6 text-end">KGO0304501</div>
                </div>
            </BlacklistItemHeader>
            <BlacklistItemBody>
                <div className="row">
                    <div className="col-2">
                        <p className="m-0 font-bold">{data.name}</p>
                        <span className="font-small">{data.role}</span>
                    </div>
                    <div className="col-5">
                        <p className="m-0 font-small">Alasan Blacklist</p>
                        <p className="m-0">{data.reason}</p>
                    </div>
                    <div className="col-3">
                        <p className="m-0 font-small">Foto</p>
                    </div>
                    <div className="col-2">
                        <Button rounded gradient>Pulihkan</Button>
                    </div>
                </div>
            </BlacklistItemBody>
        </BlacklistItemWrapper>
    );
};

export default BlacklistItem;
