import React from 'react';
import DataTable from 'react-data-table-component';
import Pagination from './Pagination';

interface ITableProps {
    columns: any[],
    data: any[],
    loading: boolean,
    totalList: number,
    paginate?: boolean,
    currentPage?: number,
    expandable?: boolean,
    selectable?: boolean,
    expandableComponents?: any,
    clearSelectedRows?: boolean,
    onSelectedRowsChange?: any,
    onRowClicked?: any,
    limitPage?: number,
    // eslint-disable-next-line no-unused-vars
    onPageChange: (event: any) => void
}

const Table: React.FC<ITableProps> = (props) => {
    const { columns, data, loading, totalList, onPageChange, paginate, currentPage, expandable, selectable, expandableComponents, clearSelectedRows, onSelectedRowsChange, onRowClicked, limitPage } = props;

    // eslint-disable-next-line react/no-unstable-nested-components
    const TablePagination = () => {
        const pageCount = Math.ceil(totalList / (limitPage || 5));
        return (
            <Pagination totalData={totalList} currentPage={currentPage!} pageCount={pageCount} onPageChange={onPageChange} limitPage={limitPage} />
        );
    };

    return (
        <DataTable
            noHeader
            columns={columns}
            data={data}
            progressPending={loading}
            pagination={paginate}
            paginationServer
            paginationTotalRows={totalList}
            paginationComponent={TablePagination}
            expandableRows={expandable}
            expandableRowsHideExpander
            expandableRowExpanded={() => true}
            expandableRowsComponent={expandableComponents}
            selectableRows={selectable}
            pointerOnHover
            highlightOnHover
            clearSelectedRows={clearSelectedRows}
            onSelectedRowsChange={onSelectedRowsChange}
            onRowClicked={onRowClicked}
            onChangePage={(page: number) => {
                onPageChange(page);
            }}
        />
    );
};

Table.defaultProps = {
    currentPage: 0,
    paginate: false,
    expandable: false,
    expandableComponents: undefined,
    selectable: false,
    clearSelectedRows: false,
    onSelectedRowsChange: undefined,
    onRowClicked: undefined,
    limitPage: 10
};

export default Table;
