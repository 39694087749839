import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
// import staticStatusPayment from '../../../@static/status-payment';
import useCurrency from '../../../helpers/useCurrency';
import API from '../../../configs/api';
import TabLink from '../components/TabLink';
import NoData from '../components/NoData';
// import Dialog from '../../../components/Dialog';

const ListPaymentTable = styled.div`
    position: relative;
    display: block;
    width: 100%;
    border-radius: 10px;
`;

const PaymentFailed: React.FC = () => {
    const navigate = useNavigate();
    const [isPending, setPending] = useState(true);
    const [data, setData] = useState([]);
    const [totalRow, setTotalRow] = useState(0);

    const fetchData = async (page: number) => {
        const payload = {
            query: { page, limit: 10000 }
        };
        await API.paymentStatusFailed(payload).then((result: any) => {
            setData(result.data);
            setTotalRow(result.total_data);
            setPending(false);
            // console.log(result);
        });
    };

    useEffect(() => {
        fetchData(1);
    }, []);

    // ** Table Payment List Column
    const columns = [
        {
            name: 'Tgl & Waktu',
            sortable: true,
            minWidth: '135px',
            selector: (row: any) => row.date
        },
        {
            name: 'Nama Proyek',
            sortable: true,
            minWidth: '180px',
            selector: (row: any) => row.project_name,
            conditionalCellStyles: [
                {
                    when: (row: any) => row.project_name === 'Multi Proyek',
                    // style: (row: any) => ({ color: row.project_name === 'Multi Proyek' ? '#EB4600' : 'rgba(0,0,0,0.87);' })
                    style: {
                        color: '#EB4600'
                    }
                }
            ]
        },
        {
            name: 'Nomor Pembayaran',
            sortable: true,
            minWidth: '170px',
            selector: (row: any) => row.external_id
        },
        {
            name: 'Total Pembayaran',
            sortable: true,
            minWidth: '160px',
            style: {
                fontWeight: 600
            },
            selector: (row: any) => `Rp. ${useCurrency(parseInt(row.total, 10))}`
        },
        {
            name: 'Metode Pembayaran',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.bank
        },
        {
            name: 'Waktu Pembayaran',
            sortable: true,
            minWidth: '130px',
            selector: (row: any) => row.expired_at.split(' ')[1]
        }
    ];

    const handleRowClick = useCallback((state: any) => {
        navigate(`/status-pembayaran/${state.external_id}`);
    }, []);

    return (
        <>
            <TabLink tabActive="gagal" />
            <div className="container-fluid px-3 py-2 px-md-4 py-md-3">
                <ListPaymentTable>
                    {!isPending && data.length > 0 && (
                        <DataTable
                            noHeader
                            columns={columns}
                            onRowClicked={handleRowClick}
                            pointerOnHover
                            highlightOnHover
                            data={data}
                            progressPending={isPending}
                            pagination
                            paginationTotalRows={totalRow}
                            onChangePage={(page: any) => {
                                fetchData(page);
                            }}
                        />
                    )}
                    {!isPending && data.length < 1 && (
                        <NoData message="Tidak ada data pembayaran gagal" />
                    )}
                </ListPaymentTable>
            </div>
        </>
    );
};

export default PaymentFailed;
