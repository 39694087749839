/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import DashboardLayout from '../../layout/DashboardLayout';
import iconProfile from '../../assets/icon/icon-profile-color.svg';
import CardInformation from './components/CardInformation';
import ListActiveProject from './components/ListActiveProject';
import FloatingWaitingPayment from './components/FloatingWaitingPayment';

const GreetingWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    h5 {
        margin: 0 0 0 10px;
        padding: 0;
        font-weight: 400;
    }
`;

interface IHomeDashboard {
    profile: any,
    waitingPaymentCounter: number
}

class HomeDashboard extends React.Component<IHomeDashboard, any> {
    constructor(props: IHomeDashboard) {
        super(props);
        this.state = {};
    }

    render(): React.ReactNode {
        const { profile, waitingPaymentCounter } = this.props;
        return (
            <DashboardLayout activeMenu="dashboard">
                <div className="container-fluid p-3 p-md-4">
                    <GreetingWrapper className="mb-3">
                        <Icon custom icon={iconProfile} />
                        <h5>Halo, {profile.name}</h5>
                    </GreetingWrapper>
                    <CardInformation />
                </div>
                {waitingPaymentCounter > 0 && (
                    <FloatingWaitingPayment counter={waitingPaymentCounter} />
                )}
                <div className="divider" />
                <ListActiveProject />
            </DashboardLayout>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        profile: state.userReducer.profile,
        waitingPaymentCounter: state.uiReducer.waitingPaymentCounter
    };
};

export default connect(mapStateToProps)(HomeDashboard);
