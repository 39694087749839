import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge, Button } from '../../../components/Styled';
import iconTeamAdd from '../../../assets/icon/icon-people-team-add.svg';
import iconTrash from '../../../assets/icon/icon-trash.svg';
import iconClose from '../../../assets/icon/icon-x-square.svg';
import Icon from '../../../components/Icon';
import API from '../../../configs/api';
import DialogDeleteMember from '../components/DialogDeleteMember';
import RowMemberAction from '../components/RowMemberAction';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';
import useText from '../../../helpers/useText';
// import PopUpAction from '../components/PopUpAction';

const ListMemberTable = styled.div`
    position: relative;
    display: block;
    width: 100%;
`;

const MemberActionWrapper = styled.div<{ visible?: boolean }>`
    position: relative;
    display: block;
    width: 100%;
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.visible ? '100%' : '0%')};
    transition: .3s ease;
    margin: -40px 0 0;

    ${(props) => (props.visible ? 'margin: 0 0 10px;' : '')}
`;

const ListMember: React.FC = () => {
    const [data, setData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [selectedId, setSelectedId] = useState<any>([]);
    const [clearRows, setClearRows] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const [dialogDeleteMember, setDialogDeleteMember] = useState(false);
    const [memberToDelete, setMemberToDelete] = useState<any>({});

    const [totalRow, setTotalRow] = useState(0);
    const [limitRows, setLimitRows] = useState(10);
    const [perPage, setPerPage] = useState(1);
    const [pendingPage, setPendingPage] = useState(false);

    const handleRowSelected = useCallback((state: any) => {
        setSelectedRows(state.selectedRows);
    }, []);

    const fetchData = async (page: number, limit: number) => {
        const payload = {
            query: { page, limit }
        };
        API.member(payload).then((result: any) => {
            setTotalRow(result.total_data);
            setData(result.data);
            setIsPending(false);
            setPendingPage(false);
        });
    };

    const handleClearSelected = () => {
        setSelectedRows([]);
        setSelectedId([]);
        setClearRows(!clearRows);
    };

    const handleDeleteSelected = () => {
        if (dialogDeleteMember) {
            dispatch(action.showLoading());
            const payload = {
                body: {
                    id: selectedId
                }
            };
            API.deleteMember(payload).then(() => {
                dispatch(action.hideLoading());
                dispatch(action.showToast('success', 'Berhasil menghapus anggota'));
                setIsPending(true);
                fetchData(perPage, limitRows);
            }).catch((err) => {
                dispatch(action.hideLoading());
                dispatch(action.showToast('error', err.message));
            });
            return;
        }
        setDialogDeleteMember(true);
    };

    useEffect(() => {
        fetchData(perPage, limitRows);
    }, [perPage, limitRows]);

    useEffect(() => {
        const ids = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < selectedRows.length; i++) {
            ids.push(selectedRows[i].id);
            setSelectedId(ids);
        }
    }, [selectedRows]);

    useEffect(() => {
        if (memberToDelete.id) {
            setSelectedId(memberToDelete.id);
            handleDeleteSelected();
        }
    }, [memberToDelete]);

    // ** Table Member List Column
    const columns = [
        {
            name: 'No',
            sortable: true,
            width: '50px',
            selector: (row: any, index: any) => useText.numberingTable(index, perPage, limitRows, pendingPage)
        },
        {
            name: 'ID Anggota',
            sortable: true,
            // width: '10px',
            selector: (row: any) => row.id
        },
        {
            name: 'Nama',
            sortable: true,
            // minWidth: '170px',
            selector: (row: any) => row.name
        },
        {
            name: 'Posisi',
            sortable: true,
            // minWidth: '100px',
            selector: (row: any) => row.role
        },
        {
            name: 'No HP',
            sortable: true,
            // minWidth: '130px',
            selector: (row: any) => row.phone
        },
        {
            name: 'Email',
            sortable: true,
            // minWidth: '130px',
            selector: (row: any) => row.email
        },
        {
            name: 'Status',
            sortable: true,
            // minWidth: '80px',
            selector: (row: any) => (row.is_active === 1 ? <Badge success rounded>Aktif</Badge> : <Badge error rounded>Tidak Aktif</Badge>)
        },
        {
            allowOverflow: true,
            button: true,
            minWidth: '50px',
            cell: (row: any) => <RowMemberAction member={row} onDeleteClick={(member) => setMemberToDelete(member)} />
        }
    ];

    return (
        <>
            <div className="row align-items-center mb-3">
                <div className="col-6">
                    <p className="m-0">
                        <span className="font-bold me-2">{data.length}</span>
                        Anggota dalam proyek
                    </p>
                </div>
                <div className="col-6 text-end">
                    <Button onClick={() => navigate('/daftar-anggota/tambah-anggota')} rounded gradient>
                        <Icon custom icon={iconTeamAdd} />
                        Tambah Anggota
                    </Button>
                </div>
            </div>
            <MemberActionWrapper visible={selectedRows.length > 0}>
                <Button border bold rounded textBlack onClick={() => handleClearSelected()}>
                    <Icon custom icon={iconClose} />
                    Batalkan
                </Button>
                <Button border bold rounded primary className="ms-3" onClick={() => handleDeleteSelected()}>
                    <Icon custom icon={iconTrash} />
                    Hapus Anggota
                </Button>
            </MemberActionWrapper>
            <ListMemberTable>
                <DataTable noHeader columns={columns} selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={clearRows} pointerOnHover highlightOnHover pagination paginationServer paginationTotalRows={totalRow} paginationPerPage={10} progressPending={isPending} data={data} onChangePage={(page: any) => { setPendingPage(true); setPerPage(page); }} onChangeRowsPerPage={(limit: any) => { setLimitRows(limit); }} />
            </ListMemberTable>
            {dialogDeleteMember && (
                <DialogDeleteMember member={memberToDelete} many={selectedRows.length > 0} dialogHandler={(showDialog) => setDialogDeleteMember(showDialog)} onDeleteConfirm={() => handleDeleteSelected()} />
            )}
        </>
    );
};

export default ListMember;
