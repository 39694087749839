import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
// import staticStatusPayment from '../../../@static/payment-proccess';
import useCurrency from '../../../helpers/useCurrency';
import API from '../../../configs/api';
import TabLink from '../components/TabLink';
import NoData from '../components/NoData';
import { Badge } from '../../../components/Styled';
import RowProccessAction from '../components/RowProccessAction';
import iconAlert from '../../../assets/icon/icon-alert.svg';
import Icon from '../../../components/Icon';
// import Dialog from '../../../components/Dialog';

const ListPaymentTable = styled.div`
    position: relative;
    display: block;
    width: 100%;
    border-radius: 10px;
`;

const NotifWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding 15px 20px;
    border-radius: 20px;
    background: #FFF4F5;
    gap: 20px;
`;

const PaymentProcess: React.FC = () => {
    const [isPending, setPending] = useState(true);
    const [data, setData] = useState([]);
    const [totalRow, setTotalRow] = useState(0);

    const fetchData = async (page: number) => {
        const payload = {
            query: { page, limit: 10000 }
        };
        await API.paymentStatusInProgress(payload).then((result: any) => {
            setData(result.data);
            setTotalRow(result.total_data);
            // setData(staticStatusPayment);
            setPending(false);
        });
    };

    useEffect(() => {
        fetchData(1);
    }, []);

    // ** Table Payment List Column
    const columns = [
        {
            name: 'Tgl & Waktu',
            sortable: true,
            minWidth: '135px',
            selector: (row: any) => row.created_date
        },
        {
            name: 'Nama Proyek',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.project_name,
            conditionalCellStyles: [
                {
                    when: (row: any) => row.project_name === 'Multi Proyek',
                    // style: (row: any) => ({ color: row.project_name === 'Multi Proyek' ? '#EB4600' : 'rgba(0,0,0,0.87);' })
                    style: {
                        color: '#EB4600'
                    }
                }
            ]
        },
        {
            name: 'Nomor Pembayaran',
            sortable: true,
            minWidth: '170px',
            selector: (row: any) => row.external_id
        },
        {
            name: 'Total Pembayaran',
            sortable: true,
            minWidth: '160px',
            style: {
                fontWeight: 600
            },
            selector: (row: any) => `Rp. ${useCurrency(parseInt(row.amount, 10))}`
        },
        {
            name: 'Metode Pembayaran',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.payment_method
        },
        {
            name: 'Waktu Pembayaran',
            sortable: true,
            minWidth: '130px',
            selector: (row: any) => <Badge error rounded>{row.created_date.split(' ')[1]}</Badge>
        },
        {
            allowOverflow: true,
            button: true,
            minWidth: '50px',
            cell: (row: any) => <RowProccessAction data={row} />
        }
    ];

    return (
        <>
            <TabLink tabActive="proses" />
            <div className="container-fluid px-3 py-2 px-md-4 py-md-3">
                {data.length > 0 && (
                    <NotifWrapper className="mb-2">
                        <Icon custom icon={iconAlert} />
                        <span>
                            Kamu dapat membatalakan pembayaran yang sudah dibayarkan dalam waktu
                            <strong> 15 menit</strong>
                        </span>
                    </NotifWrapper>
                )}
                <ListPaymentTable>
                    {!isPending && data.length > 0 && (
                        <DataTable
                            noHeader
                            columns={columns}
                            data={data}
                            pointerOnHover
                            highlightOnHover
                            pagination
                            progressPending={isPending}
                            paginationTotalRows={totalRow}
                            onChangePage={(page: any) => {
                                fetchData(page);
                            }}
                        />
                    )}
                    {!isPending && data.length < 1 && (
                        <NoData message="Tidak ada data dalam proses" />
                    )}
                </ListPaymentTable>
            </div>
        </>
    );
};

export default PaymentProcess;
