import React from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import Icon from '../../../components/Icon';
import { Badge, Button } from '../../../components/Styled';
import useCurrency from '../../../helpers/useCurrency';
import useDate from '../../../helpers/useDate';
import iconPeopleTeamAdd from '../../../assets/icon/icon-people-team-add.svg';
import iconWallet from '../../../assets/icon/icon-wallet.svg';
import noImage from '../../../assets/img/no-image.png';
import action from '../../../configs/redux/action';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import NoData from '../../../components/NoData';

const CardProjectWrapper = styled.div`
    position: relative;
    width: 100%;
    border-radius: 20px;
    background: #4E4E4E;
    box-shadow: 0px 4px 16px rgba(146, 146, 146, 0.25);
    margin: 25px 0;

    .item-project-header {
        position: relative;
        padding: 10px 20px;
        background: #2F2F2F;
        color: #FFF;
        border-radius: 20px 20px 0 0;
    }

    .item-project-body {
        position: relative;
        display: block;
        width: 100%;
        padding: 20px;

        .item-project-detail {
            position: relative;
            display: block;
            width: 100%;
            padding: 15px;
            background: var(--color-white);
            border-radius: 20px;

            img {
                filter: grayscale(100%);
                opacity: 70%;
            }
        }
    }
    
    .item-project-footer {
        position: relative;
        display: block;
        background: #686868;
        width: 100%;
        margin: 20px 0 0;
        padding: 15px 20px;
        color: #FFF;
        border-radius: 20px;

        .profile-img {
            width: 40px;
            height: 40px;
            margin: auto;

            img {
                height: 100%;
                border-radius: 100px;
            }
        }
    }
`;

const DetailPaymentWorker = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 20px 0;
    background: #fff;
    border-radius: 0 0 15px 15px;

    .rdt_Table {
        padding: 0 1rem 10px;
        border: none;
    }
`;

interface ICardProjectProps {
    data: any
}

const CardProject: React.FC<ICardProjectProps> = (props) => {
    const { data } = props;
    const dispatch = useAppDispatch();

    const onDetailClick = (id: any) => {
        dispatch(action.drawerProfile(id, true));
    };

    // ** Table Payment List Column
    const columns = [
        {
            name: 'Nama',
            sortable: true,
            minWidth: '135px',
            selector: (row: any) => row.name,
            style: {
                color: '#EB4600'
            }
        },
        {
            name: 'ID Anggota',
            sortable: true,
            selector: (row: any) => row.user_id
        },
        {
            name: 'Posisi',
            sortable: true,
            selector: (row: any) => row.role
        },
        {
            name: 'Nominal',
            sortable: true,
            style: {
                fontWeight: 600
            },
            selector: (row: any) => `Rp. ${useCurrency(parseInt(row.amount, 10))}`
        },
        {
            name: 'Tipe Pembayaran',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.label
        },
        {
            name: 'Tanggal',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => useDate.formatDate(row.created_at)
        },
        {
            name: 'Status',
            sortable: true,
            minWidth: '130px',
            selector: (row: any) => <Badge success={row.status === 'SUCCESS'} error={row.status === 'FAILED' || row.status === 'REJECT'} rounded>{row.status}</Badge>
        }
    ];

    return (
        <CardProjectWrapper>
            <div className="item-project-header">
                <div className="row">
                    <div className="col-6">
                        <span>{useDate.getMomentDate(data.created_at.split(' ')[0])}</span>
                    </div>
                    <div className="col-6 text-end">
                        <span>{data.invoice}</span>
                    </div>
                </div>
            </div>
            <div className="item-project-body">
                <div className="item-project-detail">
                    <div id="project-title">
                        <span className="font-large font-bold">{data.project_code}</span>
                        <span className="font-large"> | </span>
                        <span className="font-large">{data.project_name}</span>
                        <p>{data.client_name}</p>
                    </div>
                    <div id="project-detail">
                        <p className="m-0">{data.address}</p>
                        <p className="m-0">
                            <span style={{ color: '#A5A5A6' }}>Catatan : </span>
                            <span className="fst-italic" style={{ color: '#2F2F2F' }}>{data.note}</span>
                        </p>
                    </div>
                    <div className="lines my-2" />
                    <div className="row">
                        <div className="col-6 border-end">
                            <p className="mb-1">Anggota yang diajukan</p>
                            <div>
                                <Icon custom icon={iconPeopleTeamAdd} />
                                <span className="px-2 align-middle font-bold font-large">{data.total_workers}</span>
                            </div>
                        </div>
                        <div className="col-6 ps-5">
                            <p className="mb-1">Total Pengajuan</p>
                            <div>
                                <Icon custom icon={iconWallet} />
                                <span className="px-2 align-middle font-bold font-large">{`Rp${useCurrency(data.total_request)}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item-project-footer">
                    <div className="row">
                        <div className="col-6">
                            <div className="row my-2 justify-content-between">
                                <div className="col-profile">
                                    <div className="profile-img">
                                        {data.profile_image && (
                                            <img src={data.profile_image} alt={data.pm_name} />
                                        )}
                                        {!data.profile_image && (
                                            <img src={noImage} alt={data.pm_name} />
                                        )}
                                    </div>
                                </div>
                                <div className="col">
                                    <p className="mb-1">Project Manager (PM)</p>
                                    <p className="mb-1 font-bold">{data.pm_name}</p>
                                    <p className="m-0">{data.pm_id}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-end">
                            <Button rounded gradient onClick={() => onDetailClick(data?.pm_id)}>Lihat Profil</Button>
                        </div>
                    </div>
                </div>
            </div>
            <DetailPaymentWorker>
                {data.user.length > 0 ? (
                    <DataTable
                        noHeader
                        columns={columns}
                        data={data.user}
                        highlightOnHover
                    />
                ) : (
                    <NoData message="Tidak ada pembayaran" />
                )}
            </DetailPaymentWorker>
        </CardProjectWrapper>
    );
};

export default CardProject;
