import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Label, TextInput } from '../../../components/Styled';
import iconSelector from '../../../assets/img/user-role-selector.png';
import iconPelaksana from '../../../assets/img/role-pelaksana.png';
import iconLogistik from '../../../assets/img/role-logistik.png';
import iconProyekManajer from '../../../assets/img/role-proyek-manajer.png';
import iconFinance from '../../../assets/img/role-finance.png';
import iconFinanceApproval from '../../../assets/img/role-finance-approval.png';
import ImageDrop from '../../../components/ImageDrop';
import SelectInput from '../../../components/SelectInput';
import API from '../../../configs/api';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';
import BackButton from '../../../components/BackButton';

const NumberId = styled.div`
    position: relative;
    display: inline-block;
    width: auto;
    padding: 10px 15px;
    margin: 0 0 20px;
    background: #F7F5F5;
    text-align: center;
    color: #959598;
    border: 1px solid #D8CFCD;
    border-radius: 100px;

    span#user-id {
        color: var(--color-base-text-light);
    }

    .action-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;

        @media only screen and (max-width: 768px) {
            position: relative;
        }
    }
`;

const statusOptions = [
    {
        title: 'Aktif',
        value: 'ACTIVE'
    },
    {
        title: 'Tidak Aktif',
        value: 'NONACTIVE'
    }
];

const roleOptions = [
    {
        title: 'Proyek Manajer',
        icon: iconProyekManajer,
        description: 'Membuat proyek dan mengajukan permintaan pembayaran ke finance.',
        value: 'PM-KONTRAKTOR'
    },
    {
        title: 'Pelaksana',
        icon: iconPelaksana,
        description: 'Manajemen tukang dalam proyek dan melakukan pembayaran upah.',
        value: 'PELAKSANA'
    },
    {
        title: 'Logistik',
        icon: iconLogistik,
        description: 'Membantu monitoring kebutuhan logistik dalam proyek dan mencatat laporan keuangan pembelian.',
        value: 'LOGISTIK'
    },
    {
        title: 'Finance',
        icon: iconFinance,
        value: 'FINANCE-KONTRAKTOR',
        description: 'Finance tidak dapat proses pembayaran namun dapat melihat permintaan pembayaran yang masuk dan laporan.',
        note: 'view only'
    },
    {
        title: 'Finance Approval',
        icon: iconFinanceApproval,
        description: 'Dapat melakukan proses pembayaran dan melihat laporan namun tidak dapat menambahkan user baru.',
        value: 'FINANCE-MANAGER-KONTRAKTOR'
    }
];

const UpdateMember: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user_id } = useParams();
    const [role, setRole] = useState('');
    const [status, setStatus] = useState('');
    const [selectedRole, setSelectedRole] = useState<any>({});
    const [selectedStatus, setSelectedStatus] = useState<any>({});
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [image, setImage] = useState<any>({});
    const [defaultImage, setDefaultImage] = useState('');

    const handleImageInput = (file: any) => {
        setImage(file);
    };

    useEffect(() => {
        setRole(selectedRole.title);
    }, [selectedRole]);

    useEffect(() => {
        setStatus(selectedStatus.title);
    }, [selectedStatus]);

    const fetchData = async () => {
        const payload = {
            query: { user_id }
        };
        await API.memberDetail(payload).then((result: any) => {
            const { user } = result.data;
            const [defaultRole] = roleOptions.filter((item: any) => item.value === user.role_name?.replaceAll(' ', '-'));
            const userStatus = user.is_active === 1 ? 'ACTIVE' : 'NONACTIVE';
            const [defaultStatus] = statusOptions.filter((item: any) => item.value === userStatus);
            setName(user.name);
            setSelectedRole(defaultRole);
            setPhone(user.phone);
            setEmail(user.email);
            setDefaultImage(user.profile_image);
            setSelectedStatus(defaultStatus);
            // console.log(defaultStatus);
        });
    };

    useEffect(() => {
        if (!user_id) {
            navigate('/not-found', { replace: true });
            return;
        }
        fetchData();
    }, []);

    const handleSubmit = () => {
        dispatch(action.showLoading());
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('role', selectedRole.value);
        formData.append('profile_image', image);
        const payload = {
            body: formData
        };
        API.createMember(payload).then(() => {
            dispatch(action.hideLoading());
            dispatch(action.showToast('success', 'Berhasil menambahkan anggota'));
            navigate('/daftar-anggota', { replace: true });
        }).catch((err) => {
            dispatch(action.hideLoading());
            dispatch(action.showToast('error', err?.message));
        });
    };

    return (
        <>
            <p className="mb-4">Kamu dapat melakukan perubahan anggota kamu</p>
            <div className="row">
                <div className="col-11 p-4" style={{ background: '#FAFAFA', borderRadius: 20, border: '1px solid #F7F5F5' }}>
                    <NumberId>
                        <span>Nomor ID : </span>
                        <span id="user-id" className="font-bold">{user_id}</span>
                    </NumberId>
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-8">
                            <Label bold htmlFor="name">Status</Label>
                            <SelectInput className={`select-input ${status === 'Aktif' ? 'active' : 'non-active'}`} style={{ marginBottom: '1rem' }} placeholder="Status" headerPlaceholder="Status" value={status} optionIcon={iconSelector} options={statusOptions} onOptionSelected={(item: any) => setSelectedStatus(item)} />
                            <Label bold htmlFor="name">Tugas dan Hak akses</Label>
                            <SelectInput style={{ marginBottom: '1rem' }} placeholder="Pilih tugas dan hak akses" headerPlaceholder="Silakan tambahkan Tugas" optionHeader value={role} optionIcon={iconSelector} options={roleOptions} onOptionSelected={(item: any) => setSelectedRole(item)} />
                            <Label bold htmlFor="name">Nama</Label>
                            <TextInput className="mb-3" block border id="name" type="text" placeholder="Nama" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)} value={name} />
                            <Label bold htmlFor="phone">Nomor Telepon</Label>
                            <TextInput className="mb-3" block border id="phone" type="text" placeholder="Nomor Telepon" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)} value={phone} />
                            <Label bold htmlFor="email">Email</Label>
                            <TextInput className="mb-3" block border id="email" type="text" placeholder="Email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)} value={email} />
                        </div>
                        <div className="col-profile mt-4">
                            {defaultImage && (
                                <ImageDrop defaultSrc={{ src: defaultImage }} onImageSelected={(file: any) => handleImageInput(file)} />
                            )}
                            {!defaultImage && (
                                <ImageDrop onImageSelected={(file: any) => handleImageInput(file)} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="action-wrapper mt-2 mt-md-3 p-3 p-md-4">
                <div className="row">
                    <div className="col-4 col-md-8" />
                    <div className="col-4 col-md-2">
                        <BackButton />
                    </div>
                    <div className="col-4 col-md-2">
                        <Button block rounded gradient onClick={() => handleSubmit()}>Simpan</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateMember;
