import React from 'react';
import Dialog from './Dialog';
import ListBlacklist from './TabDrawer/ListBlacklist';

interface ITabState {
    data: any[],
    loading: boolean,
    total_data: number,
    total_page: number
}

interface IBlacklistDialog {
    data: ITabState,
    page: number,
    // eslint-disable-next-line no-unused-vars
    onPageChange: (event: any) => void
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: any) => void
}

const BlacklistDialog:React.FC<IBlacklistDialog> = (props) => {
    const { data, page, onPageChange, dialogHandler } = props;
    return (
        <Dialog fullWidth closeBtn dialogHandler={dialogHandler}>
            <ListBlacklist
                data={data}
                page={page}
                onPageChange={(event: any) => {
                    onPageChange(event);
                }}
            />
        </Dialog>
    );
};

export default BlacklistDialog;
