import React from 'react';
import DashboardLayout from '../../layout/DashboardLayout';

const About: React.FC = () => {
    return (
        <DashboardLayout activeMenu="tentang">
            <div className="container-fluid p-3 p-md-4">
                <div className="row my-3">
                    <div className="col-12 mb-2">
                        <h5 className="font-bold">Tentang Kanggo</h5>
                    </div>
                    <div className="col-10">
                        <p className="m-0 font-bold font-large">Kami adalah KANGGO</p>
                        <span>Di Kanggo, kami mengerti bahwa waktu Anda sangat penting dan berharga. Sama penting dan berharganya dengan pekerjaan home improvement yang harus Anda lakukan, namun sangat memakan waktu. Kami bisa membantu Anda.</span>
                        <br />
                        <br />
                        <p className="m-0 font-bold">Beres lebih cepat dan lebih mudah</p>
                        <span>Layanan tuKANG GO online kami secara real time menghubungkan Anda dengan tenaga kerja profesional yang ahli di bidangnya masing-masing. Mitra kami dengan senang hati akan membantu Anda melakukan pekerjaan home improvement, sehingga Anda dapat memiliki waktu untuk tetap produktif setiap harinya.</span>
                        <br />
                        <br />
                        <span>Bagi para pekerja konstruksi dan para mitra tukang, aplikasi Kanggo juga mempermudah proses mencari dan menerima pesanan. Mitra tukang pun juga dapat meningkatkan kualitas hidup bersama Kanggo.</span>
                        <br />
                        <br />
                        <p className="m-0 font-bold">#TukangReadyToGo</p>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default About;
