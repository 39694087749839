import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoKanggo from '../assets/img/logo-kanggo-red.png';
import logoKanggoMini from '../assets/img/logo-kanggo-mini-red.png';
import Icon from './Icon';
import menuList from '../helpers/menuList';
import { Badge } from './Styled';
import API from '../configs/api';
import action from '../configs/redux/action';
import { useAppDispatch, useAppSelector } from '../hooks/useAppReducer';

const SidebarWrapper = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    max-width: calc(100% - 80%);
    height: 100%;
    background: #f4f4f4;
    padding: 50px 1rem;
    top: 0;
    left: 0;
    transition: .3s ease;
    border-right: 1px solid #EBE7E6;
    z-index: 99;

    &.minimize {
        max-width: calc(100% - 93%) !important;
    }

    @media only screen and (max-width: 768px) {
        left: -120%;
        &.visible {
            max-width: 80%;
            left: 0;
        }

        &.minimize {
            max-width: 80% !important;
        }
    }
`;

const SidebarHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    .drawer-logo {
        position: relative;
        height: 60px;
        width: 100%;
        text-align: center;

        img {
            position: relative;
            display: block;
            margin: auto;
            height: 100%;
        }
    }
    
    
    #mini {
        display: none !important;
    }

    #full {
        display: block !important;
    }

    &.minimize {
        margin-bottom: 20px;
        .drawer-logo {
            height: 40px;
        }

        #full {
            display: none !important;
        }
        #mini {
            display: block !important;
        }
    }
`;

const SidebarToggle = styled.div`
    position: absolute;
    display: flex;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    background: var(--color-white);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    transform: rotate(0);
    transition: .3s ease;
    z-index: 1;
    cursor: pointer;

    &.minimize {
        transform: rotate(180deg);
    }
    
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const MobileSidebarToggle = styled.div`
    position: absolute;
    display: none;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    background: var(--color-white);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    transform: rotate(0);
    transition: .3s ease;
    z-index: 1;
    cursor: pointer;
    
    @media only screen and (max-width: 768px) {
        display: flex;
    }
`;

const HoverWrapper = styled.div`
    position: absolute;
    display: none;
    width: auto;
    min-width: 120px;
    padding: 5px 1rem;
    color: var(--color-white);
    background: #2F2F2F;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 100px;
    top: 50%;
    left: 110%;
    transform: translateY(-50%);
    opacity: 70%;
    z-index: 1;
`;

const MenuList = styled.ul`
    position: relative;
    display: block;
    margin: none;
    padding: 0;
    list-style: none;
`;

const MenuItem = styled.li`
    position: relative;
    display: block;
    width: 100%;

    
    a {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        color: var(--color-base-text-light);
        padding: 10px 1rem;
        border-radius: 10px;
        margin-bottom: 10px;
        transition: background .3s ease;
        cursor: pointer;
    
        &:hover {
            background: #ebebeb;
        }

        span {
            position: relative;
            display: inline-block;
            margin-left: .5rem;
            vertical-align: middle;
        }
    
        img {
            filter: grayscale(100%);
            opacity: 40%;
        }

        &.active {
            img {
                filter: none;
                opacity: 100%;
            }
            background: var(--color-white);
            font-weight: var(--font-bold);
        }

        ${Badge} {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
        }
    }

    &.minimize {
        text-align: center;
        &:hover ${HoverWrapper} {
            display: block;
        }
    }
`;

interface ISidebar {
    activeMenu?: string
}

const Sidebar = (props: ISidebar) => {
    const { activeMenu } = props;
    const dispatch = useAppDispatch();
    const { waitingPaymentCounter } = useAppSelector((state) => state.uiReducer);
    const { profile } = useAppSelector((state) => state.userReducer);
    const [minimize, setMinimize] = useState(false);
    const [showTitle, setShowTitle] = useState(true);

    const fetchData = () => {
        let total: number = 0;
        API.paymentStatusPending().then((result: any) => {
            total = result.total_data;
            dispatch(action.setWaitingPaymentCounter(total));
        });
    };

    useEffect(() => {
        setShowTitle(false);
        setTimeout(() => {
            if (!minimize) {
                setShowTitle(true);
            }
        }, 310);
    }, [minimize]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleSidebar = (mode: any) => {
        const header = document.getElementById('header');
        const dashboardMain = document.getElementById('dashboard-main');
        const drawer = document.getElementById('nav-drawer');
        if (mode === 'mobile') {
            drawer?.classList.remove('visible');
            setMinimize(false);
            header?.classList.remove('drawer-minimize');
            dashboardMain?.classList.remove('drawer-minimize');
            return;
        }
        setMinimize(!minimize);
        setTimeout(() => {
            if (minimize) {
                header?.classList.remove('drawer-minimize');
                dashboardMain?.classList.remove('drawer-minimize');
                return;
            }
            header?.classList.add('drawer-minimize');
            dashboardMain?.classList.add('drawer-minimize');
        }, 100);
    };

    const menuItem = menuList.filter((i: any) => {
        return i.drawer === true && i.roles.indexOf(profile.role_name) !== -1;
    });

    return (
        <SidebarWrapper id="nav-drawer" className={minimize ? 'minimize' : ''}>
            <SidebarToggle className={minimize ? 'minimize' : ''} onClick={() => handleSidebar('desktop')}>
                <Icon icon="chevron-left" />
            </SidebarToggle>
            <MobileSidebarToggle onClick={() => handleSidebar('mobile')}>
                <Icon icon="chevron-left" />
            </MobileSidebarToggle>
            <SidebarHeader className={minimize ? 'minimize' : ''}>
                <div className="drawer-logo">
                    <img id="full" src={logoKanggo} alt="Kanggo Finance" />
                    <img id="mini" src={logoKanggoMini} alt="Kanggo Finance" />
                </div>
            </SidebarHeader>
            <MenuList>
                {menuItem.map((i: any) => (
                    <MenuItem key={i.id} className={`${activeMenu === i.tag ? 'active' : ''} ${minimize ? 'minimize' : ''}`}>
                        <Link className={`${activeMenu === i.tag ? 'active' : ''} ${minimize ? 'minimize' : ''}`} to={`${i.tag === 'dashboard' ? '/' : `/${i.tag}`}`}>
                            <Icon custom icon={i.icon} />
                            {!minimize && showTitle && (
                                <>
                                    <span>{i.title}</span>
                                    {i.tag === 'status-pembayaran' && waitingPaymentCounter > 0 && (
                                        <Badge red style={{ padding: '2px 7px', marginLeft: 5 }}>{waitingPaymentCounter}</Badge>
                                    )}
                                </>
                            )}
                            {minimize && (
                                <HoverWrapper>{i.title}</HoverWrapper>
                            )}
                        </Link>
                    </MenuItem>
                ))}
            </MenuList>
        </SidebarWrapper>
    );
};

Sidebar.defaultProps = {
    activeMenu: ''
};

export default Sidebar;
