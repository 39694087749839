/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import API from '../configs/api';
import useDate from '../helpers/useDate';
import useDetailMember from '../hooks/useDetailMember';
import Drawer from './Drawer';
import Icon from './Icon';
import { Badge, Card, TabContent, TabItem, TabLayout } from './Styled';
import iconPhone from '../assets/icon/icon-phone.svg';
import iconMail from '../assets/icon/icon-email.svg';
import noImage from '../assets/img/no-image.png';
import NoData from './NoData';
import Table from './Table';
import ListActivity from './TabDrawer/ListActivity';
import ListProject from './TabDrawer/ListProject';
import ListExperience from './TabDrawer/ListExperience';
import ListBlacklist from './TabDrawer/ListBlacklist';
import BlacklistDialog from './BlacklistDialog';

const ProfileHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 2rem 1.5rem;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 50%;
        background: linear-gradient(93.93deg, #D9272D 3.26%, #EB4600 96.83%);
        top: 0;
        left: 0;
        z-index: -1;

    }

    &.proyek-manajer::before {
        background: #3185FC !important;
    }

    &.logistik::before {
        background: linear-gradient(93.93deg, #D5B615 3.26%, #BD1EF5 95.17%) !important;
    }

    &.mandor::before {
        background: linear-gradient(93.93deg, #D9272D 3.26%, #EB4600 96.83%) !important;
    }

    &.tukang::before {
        background: linear-gradient(93.93deg, #6BE69C 3.26%, #03B7CF 96.83%) !important;
    }

    &.pelaksana::before {
        background: linear-gradient(93.93deg, #31B3FC 3.26%, #F1EA49 96.83%) !important;
    }

    &.non-active::before {
        background: #959598 !important;
    }

    &::after {
        content: '';
        position: absolute;
        width: calc(100% - 5rem);
        height: 5px;
        left: 50%;
        bottom: 0;
        background-image: linear-gradient(to right, #D8CFCD 50%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 10px 2px;
        background-repeat: repeat-x;
        transform: translateX(-50%);
    }
`;

const ProfileImage = styled.div`
    position: relative;
    display: block;
    width: 165px;
    height: 165px;
    border: 10px solid var(--color-white);
    border-radius: 100px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 100px;
    }
`;

const ContactWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;

    #contact {
        position: relative;
        padding: 2px 15px;
        margin: 10px 0;
        &:first-child {
            padding-left: 0;
            border-right: 1px solid #e7e7e7;
        }
        &:last-child {
            padding-right: 0;
        }
    }
`;

const SkillsWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    align-items: center;

    ${Badge} {
        margin-left: 10px;
        &:first-child {
            margin-left: 0;
        }
    }
`;

const ProfileBody = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 1rem 1.5rem;
`;

const ButtonUpdate = styled.div`
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #fff;
    border-radius: 100px;
    font-size: var(--font-small);
    color: #fff;
`;

const BadgeNonActive = styled.div`
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    background: #fff;
    border: 1px solid var(--color-base-primary-light);
    border-radius: 100px;
    margin-left: 10px;
    font-size: var(--font-small);
    color: var(--color-base-primary-light);
`;

interface IDrawerProfile {
    userId: any,
    // eslint-disable-next-line no-unused-vars
    drawerHandler: (showDrawer: boolean) => void
}

interface ITabState {
    data: any[],
    loading: boolean,
    total_data: number,
    total_page: number
}

const DrawerProfile: React.FC<IDrawerProfile> = (props) => {
    const { userId, drawerHandler } = props;
    const [data, setData] = useState<any>({});
    const [skills, setSkills] = useState([]);
    const [moreSkills, setMoreSkills] = useState(0);
    const [isPending, setPending] = useState(true);
    const [activeTab, setActiveTab] = useState('aktifitas');
    const [role, setRole] = useState('');
    const [dialogBlacklist, setDialogBlacklist] = useState(false);

    const [pageAct, setPageAct] = useState(1);
    const [dataAct, setDataAct] = useState<ITabState>({
        data: [],
        loading: false,
        total_data: 0,
        total_page: 0
    });
    const [pageProject, setPageProject] = useState(1);
    const [dataProject, setDataProject] = useState<ITabState>({
        data: [],
        loading: false,
        total_data: 0,
        total_page: 0
    });
    const [pageExp, setPageExp] = useState(1);
    const [dataExp, setDataExp] = useState<ITabState>({
        data: [],
        loading: false,
        total_data: 0,
        total_page: 0
    });
    const [pageBlacklist, setPageBlacklist] = useState(1);
    const [dataBlacklist, setDataBlacklist] = useState<ITabState>({
        data: [],
        loading: false,
        total_data: 0,
        total_page: 0
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [limitBlacklist, setLimitBlacklist] = useState(5);

    const {
        list: listAct,
        loading: loadingAct,
        totalList: totalListAct,
        totalPage: totalPageAct
    } = useDetailMember({ path: '/activity', currentPage: pageAct, userId, isTotalPage: true });
    const {
        list: listProject,
        loading: loadingProject,
        totalList: totalListProject,
        totalPage: totalPageProject
    } = useDetailMember({ path: '/project', currentPage: pageProject, userId, isTotalPage: true });
    const {
        list: listExp,
        loading: loadingExp,
        totalList: totalListExp,
        totalPage: totalPageExp
    } = useDetailMember({ path: '/experience', currentPage: pageExp, userId, isTotalPage: true });
    const {
        list: listBlacklist,
        loading: loadingBlacklist,
        totalList: totalListBlacklist,
        totalPage: totalPageBlacklist
    } = useDetailMember({ path: '/blacklist', currentPage: pageBlacklist, userId, isTotalPage: true, limit: limitBlacklist });

    const fetchAct = () => {
        setDataAct({
            data: listAct,
            loading: loadingAct,
            total_data: totalListAct,
            total_page: totalPageAct
        });
    };

    const fetchProject = () => {
        setDataProject({
            data: listProject,
            loading: loadingProject,
            total_data: totalListProject,
            total_page: totalPageProject
        });
    };

    const fetchExp = () => {
        setDataExp({
            data: listExp,
            loading: loadingExp,
            total_data: totalListExp,
            total_page: totalPageExp
        });
    };

    const fetchBlacklist = () => {
        setDataBlacklist({
            data: listBlacklist,
            loading: loadingBlacklist,
            total_data: totalListBlacklist,
            total_page: totalPageBlacklist
        });
    };

    const onPageChange = (event: any) => {
        switch (activeTab) {
        case 'aktifitas':
            setPageAct(event.selected + 1);
            fetchAct();
            break;

        case 'proyek_aktif':
            setPageProject(event.selected + 1);
            fetchProject();
            break;

        case 'pengalaman':
            setPageExp(event.selected + 1);
            fetchExp();
            break;

        case 'blacklist':
            setPageBlacklist(event.selected + 1);
            fetchBlacklist();
            break;

        default:
            break;
        }
    };

    const columnsPM = useMemo(() => [
        {
            name: 'Tanggal Dibuat',
            minWidth: '80px',
            selector: (row: any) => useDate.formatDate(row.created_at, true, ' - ')
        },
        {
            name: 'Kode Proyek',
            minWidth: '50px',
            selector: (row: any) => row.project_code
        },
        {
            name: 'Nama Proyek',
            minWidth: '130px',
            selector: (row: any) => row.project_name
        },
        {
            name: 'Client',
            minWidth: '130px',
            selector: (row: any) => row.client_name
        },
        {
            name: 'Tanggal Mulai',
            minWidth: '80px',
            selector: (row: any) => useDate.formatDate(row.start_date)
        },
        {
            name: 'Target SPK',
            minWidth: '80px',
            selector: (row: any) => (row.target_spk ? useDate.formatDate(row.target_spk) : '-')
        },
        {
            name: 'Anggota',
            minWidth: '30px',
            selector: (row: any) => row.total_workers
        }
    ], []);

    const handleDetailBlacklist = () => {
        setPageBlacklist(1);
        setLimitBlacklist(2);
        setDialogBlacklist(!dialogBlacklist);
    };

    const fetchData = () => {
        const user_id = userId;
        const payload = {
            query: { user_id }
        };
        API.memberDetail(payload).then((result: any) => {
            setRole(result.data.user?.role_name.toLowerCase().split(' ').join('-'));
            setData(result.data);
            setPending(false);
        });
    };

    const handleTab = (value: string) => {
        setCurrentPage(1);
        setActiveTab(value);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchAct();
        fetchProject();
        fetchExp();
        fetchBlacklist();
    }, [loadingAct, loadingProject, loadingExp, loadingBlacklist]);

    useEffect(() => {
        fetchBlacklist();
    }, [limitBlacklist]);

    useEffect(() => {
        if (data && data.skills) {
            const skill = data.skills.slice(0, 5);
            setMoreSkills(data.skills.length + 1 - skill.length);
            setSkills(skill);
        }
    }, [data]);

    return (
        <Drawer noPadding right drawerHandler={drawerHandler}>
            <ProfileHeader className={`${role}${data.user?.is_active === 0 ? ' non-active' : ''}`}>
                <div className="container-fluid">
                    {!isPending && (
                        <div className="row align-items-center">
                            <div className="col-2">
                                <ProfileImage>
                                    {data.user.profile_image && (
                                        <img src={data.user.profile_image} alt={data.user.name} />
                                    )}
                                    {!data.user.profile_image && (
                                        <img src={noImage} alt={data.user.name} />
                                    )}
                                </ProfileImage>
                            </div>
                            <div className="col-10">
                                <div className="row position-relative">
                                    <div className="col-12 mb-2">
                                        <ButtonUpdate>Ubah Profile</ButtonUpdate>
                                        {data.user?.is_active === 0 && (
                                            <BadgeNonActive>Tidak Aktif</BadgeNonActive>
                                        )}
                                    </div>
                                    <div className="col-3">
                                        <h4 className="mb-1 font-bold text-white text-nowrap text-truncate">{data.user.name}</h4>
                                        <p className="mb-1 text-white font-small">
                                            <span>{data.user.gender === 'MALE' ? 'Laki-laki' : 'Perempuan'}</span>
                                            <span>  |  </span>
                                            <span className="font-bold">{data.user.role_name}</span>
                                        </p>
                                        <p className="m-0 position-absolute d-flex flex-column">
                                            {data.total_blacklist > 0 && (
                                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                <span onClick={handleDetailBlacklist} className="color-cs-primary fw-semibold cursor-pointer">Lihat Detail</span>
                                            )}
                                            <div className="d-flex">
                                                <span className="color-gray-primary">Tanggal bergabung : </span>
                                                <span className="ms-1">{useDate.splitDate(data.user.join_date)}</span>
                                            </div>
                                        </p>
                                    </div>
                                    <div className="col-3 text-center">
                                        <Card shadow>
                                            <p className="mb-2">Proyek Berjalan</p>
                                            <h4 className="m-0 font-bold font-extralarge">{data.total_project_active}</h4>
                                        </Card>
                                    </div>
                                    <div className="col-3 text-center">
                                        <Card shadow>
                                            <p className="mb-2">Proyek Selesai</p>
                                            <h4 className="m-0 font-bold font-extralarge">{data.total_project_finish}</h4>
                                        </Card>
                                    </div>
                                    <div className="col-3 text-center">
                                        <Card shadow>
                                            <p className="mb-2">Pengalaman</p>
                                            {data.total_experience !== 0 && (
                                                <>
                                                    <h4 className="m-0 d-inline-block font-bold font-extralarge">
                                                        {data.total_experience.split(' ')[0]}
                                                    </h4>
                                                    <p className="mb-0 ms-2 d-inline-block text-capitalize">{data.total_experience.split(' ')[1]}</p>
                                                </>
                                            )}
                                            {data.total_experience === 0 && (
                                                <h4 className="m-0 d-inline-block font-bold font-extralarge">0</h4>
                                            )}
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <ContactWrapper>
                                <div id="contact">
                                    <Icon custom icon={iconPhone} />
                                    <span className="ms-2 align-middle">{data.user.phone}</span>
                                </div>
                                <div id="contact">
                                    <Icon custom icon={iconMail} />
                                    <span className="ms-2 align-middle">{data.user.email}</span>
                                </div>
                            </ContactWrapper>
                            <SkillsWrapper>
                                {skills.length > 0 && skills.map((item: any, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Badge key={index} className="border-0" background="#F7F5F5" rounded>{item.skill}</Badge>
                                ))}
                                {skills.length > 4 && (
                                    <span className="ms-2 text-primary">{`+${moreSkills} Keahlian`}</span>
                                )}
                            </SkillsWrapper>
                        </div>
                    )}
                </div>
            </ProfileHeader>
            <ProfileBody>
                <TabLayout>
                    <TabItem
                        onClick={() => handleTab('aktifitas')}
                        className={`${activeTab === 'aktifitas' ? 'active' : ''}`}
                    >
                        Aktifitas
                    </TabItem>
                    <TabItem
                        onClick={() => handleTab('proyek_aktif')}
                        className={`${activeTab === 'proyek_aktif' ? 'active' : ''}`}
                    >
                        {`Proyek Aktif ${totalListProject > 0 ? `(${totalListProject})` : ''}`}
                    </TabItem>
                    {role === 'mandor' && (
                        <>
                            <TabItem
                                onClick={() => { handleTab('pengalaman'); }}
                                className={`${activeTab === 'pengalaman' ? 'active' : ''}`}
                            >
                                Pengalaman
                            </TabItem>
                            <TabItem
                                onClick={() => { handleTab('blacklist'); }}
                                className={`${activeTab === 'blacklist' ? 'active' : ''}`}
                            >
                                Blacklist
                            </TabItem>
                        </>
                    )}
                </TabLayout>
                <TabContent>
                    <div className="my-2">
                        {activeTab === 'aktifitas' && !dataAct.loading && (
                            <ListActivity
                                data={dataAct}
                                page={pageAct}
                                onPageChange={(event: any) => {
                                    onPageChange(event);
                                }}
                            />
                        )}
                        {activeTab === 'proyek_aktif' && role === 'mandor' && !loadingProject && totalListProject > 0 && (
                            <ListProject
                                data={dataProject}
                                page={pageProject}
                                onPageChange={(event: any) => {
                                    onPageChange(event);
                                }}
                            />
                        )}
                        {activeTab === 'proyek_aktif' && role === 'proyek-manajer' && (
                            <Table
                                paginate
                                columns={columnsPM}
                                totalList={totalListProject}
                                currentPage={currentPage}
                                loading={loadingProject}
                                data={listProject}
                                onPageChange={(e: any) => {
                                    setCurrentPage(e.selected + 1);
                                }}
                                limitPage={5}
                            />
                        )}
                        {activeTab === 'pengalaman' && !loadingExp && totalListExp > 0 && (
                            <ListExperience
                                data={dataExp}
                                page={pageExp}
                                onPageChange={(event: any) => {
                                    onPageChange(event);
                                }}
                            />
                        )}
                        {activeTab === 'blacklist' && !loadingExp && totalListBlacklist > 0 ? (
                            <ListBlacklist
                                data={dataBlacklist}
                                page={pageBlacklist}
                                onPageChange={(event: any) => {
                                    onPageChange(event);
                                }}
                            />
                        ) : activeTab === 'blacklist' && loadingExp && <NoData message="Tidak ada riwayat blacklist di akun ini" description="Semua blacklist akan tercatat dihalaman ini" />}
                    </div>
                </TabContent>
            </ProfileBody>

            {dialogBlacklist && <BlacklistDialog data={dataBlacklist} page={pageBlacklist} onPageChange={(event: any) => onPageChange(event)} dialogHandler={(showDialog: boolean) => { setDialogBlacklist(showDialog); setLimitBlacklist(5); setPageBlacklist(1); }} />}
        </Drawer>
    );
};

export default DrawerProfile;
