import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ButtonLink = styled(Link)<{ block?: boolean, rounded?: boolean, gradient?: boolean, primary?: boolean, border?: boolean, bold?: boolean, textBlack?: boolean, error?: boolean, active?: boolean }>`
    position: relative;
    display: ${(props) => (props.block ? 'block' : 'inline-block')};
    width: ${(props) => (props.block ? '100%' : 'auto')};
    min-height: 40px;
    padding: 8px 1.5rem;
    text-decoration: none;
    background: ${(props) => (props.gradient ? 'linear-gradient(90deg, #D9272D 5%, #EB4600 95%)' : 'white')};
    ${(props) => (props.textBlack ? 'color: var(--color-base-text-light);' : 'color: var(--color-white);')}
    ${(props) => (props.primary ? 'color: var(--color-base-primary-light);' : '')}
    border-radius: ${(props) => (props.rounded ? '100px' : '6px')};
    outline: none;
    ${(props) => (props.border && !props.primary ? 'border: 1px solid #8B8B8B;' : 'border: none;')}
    ${(props) => (props.border && props.primary ? 'border: 1px solid var(--color-base-primary-light);' : '')}
    ${(props) => (props.bold ? 'font-weight: var(--font-bold);' : '')}
    transition: .3s ease;
    cursor: pointer;

    &:hover {
        background: #F7F5F5;
        color: var(--color-white);
        ${(props) => (props.gradient ? 'background: linear-gradient(90deg,#b31f24 5%,#EB4600 95%);' : '')}
    }

    &:disabled {
        ${(props) => (props.gradient ? `
            background: #959598;
            color: white;
        ` : `
            background: #D8CFCD;
            color: black;
        `)};
    }

    ${(props) => (props.error ? `
        background: #FFF4F5;
        border: 1px solid #B52126;
        color: #B52126;
    ` : '')}

    ${(props) => (props.active ? `
        background: #EDFFEA;
        border: 1px solid #1BB600;
        color: #1BB600;
    ` : '')}

    img, .feather, div {
        vertical-align: middle;
        margin-bottom: 1px;
        margin-right: 5px;
    }

    img {
        ${(props) => (props.gradient ? 'filter: brightness(0) invert(1);' : '')}
    }

    img {
        ${(props) => (props.textBlack ? 'filter: brightness(0) invert(0);' : '')}
    }
`;

export const Button = styled('button').attrs((props) => ({ className: props.className }))<{ block?: boolean, rounded?: boolean, gradient?: boolean, primary?: boolean, border?: boolean, borderDashed?: boolean, bold?: boolean, textBlack?: boolean, error?: boolean, active?: boolean }>`
    position: relative;
    width: ${(props) => (props.block ? '100%' : 'auto')};
    display: ${(props) => (props.block ? 'block' : 'inline-block')};
    min-height: 40px;
    padding: 8px 1.5rem;
    background: ${(props) => (props.gradient ? 'linear-gradient(90deg, #D9272D 5%, #EB4600 95%)' : 'white')};
    ${(props) => (props.textBlack ? 'color: var(--color-base-text-light);' : 'color: var(--color-white);')}
    ${(props) => (props.primary ? 'color: var(--color-base-primary-light);' : '')}
    border-radius: ${(props) => (props.rounded ? '100px' : '6px')};
    outline: none;
    ${(props) => (props.border && !props.primary ? 'border: 1px solid #8B8B8B;' : 'border: none;')}
    ${(props) => (props.border && props.primary ? 'border: 1px solid var(--color-base-primary-light);' : '')}
    ${(props) => (props.bold ? 'font-weight: var(--font-bold);' : '')}
    transition: .3s ease;
    cursor: pointer;

    ${(props) => (props.borderDashed ? 'border-style: dashed;' : '')}

    &:hover {
        background: #F7F5F5;
        ${(props) => (props.gradient ? 'background: linear-gradient(90deg,#b31f24 5%,#EB4600 95%);' : '')}
    }

    &:disabled {
        cursor: no-drop;
        ${(props) => (props.gradient ? `
            background: #959598;
            color: white;
        ` : `
            background: #F7F5F5;
            color: black;
        `)};
    }

    ${(props) => (props.error ? `
        background: #FFF4F5;
        border: 1px solid #B52126;
        color: #B52126;
    ` : '')}

    ${(props) => (props.active ? `
        background: #EDFFEA;
        border: 1px solid #1BB600;
        color: #1BB600;
    ` : '')}

    img, .feather, div {
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 5px;
    }

    img {
        ${(props) => (props.gradient ? 'filter: brightness(0) invert(1);' : '')}
    }

    img {
        ${(props) => (props.textBlack ? 'filter: brightness(0) invert(0);' : '')}
    }
`;

export const ButtonIcon = styled.div`
    position: relative;
    display: inline-flex;
    width: 36px;
    height: 36px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    background: transparent;
    outline: none;
    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, .3);
    }
`;
