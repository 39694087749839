import { useState, useEffect } from 'react';
import API from '../configs/api';

const useContractorProject = (filter: any) => {
    const [listData, setListData] = useState<any[]>([]);

    useEffect(() => {
        const handleListData = (data: any[]) => {
            setListData(data);
        };

        const { status, start_date, end_date } = filter;

        const query: any = {};
        query.limit = 10000;

        if (status) {
            query.status = status;
        }
        if (start_date) {
            query.start_date = start_date;
        }

        if (end_date) {
            query.end_date = end_date;
        }

        const payload = {
            query
        };
        API.projectContractor(payload).then((result: any) => {
            handleListData(result.data);
        }).catch(() => {
            handleListData([]);
        });
        return () => {
            handleListData([]);
        };
    }, [filter]);

    return listData;
};

export default useContractorProject;
