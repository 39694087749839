import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import iconProfile from '../assets/icon/icon-profile-circle.svg';
import Icon from './Icon';
import ProfileMenu from './ProfileMenu';
import menuList from '../helpers/menuList';
import { useAppSelector } from '../hooks/useAppReducer';
// import { ButtonIcon } from './Styled';

const HeaderWrapper = styled.header`
    position: fixed;
    display: block;
    width: 100%;
    max-width: calc(100% - 20%);
    padding: 0;
    height: 60px;
    background: var(--color-white);
    border-bottom: 1px solid #EBE7E6;
    transition: max-width .2s ease;
    top: 0;
    right: 0;
    z-index: 98;
    
    &.drawer-minimize {
        max-width: calc(100% - 7%);
    }

    @media only screen and (max-width: 768px) {
        max-width: 100% !important;
    }
`;

const HeaderItemWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    overflow: hidden;
`;

const HeaderItem = styled.div<{ alignRight?: boolean }>`
    position: relative;
    display: block;
    width: 90%;
    ${(props) => (props.alignRight ? 'text-align: right;' : '')}
    @media only screen and (max-width: 768px) {
        &:nth-of-type(1) {
            width: 20%;
        }
        &:nth-of-type(2) {
            width: 80%;
        }
    }
`;

const ActiveMenu = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 0;

    img {
        filter: grayscale(100%);
        opacity: 40%;
    }

    span {
        position: relative;
        display: inline-block;
        font-weight: var(--font-bold);
        margin-left: .5rem;
        vertical-align: middle;
    }
    
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const ProfileWrapper = styled.div`
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
`;

const MobileDrawerToggle = styled.div`
    position: relative;
    display: none;
    width: auto;
    height: auto;
    cursor: pointer;
    
    @media only screen and (max-width: 768px) {
        display: block;
    }
`;

interface IHeader {
    activeMenu?: string
}

const Header = (props: IHeader) => {
    const { activeMenu } = props;
    const [breadcrumbActiveMenu, setBreadcrumbActiveMenu] = useState<any>({});
    const [showProfile, setShowProfile] = useState(false);
    const { name, company_name, role_name } = useAppSelector((state: any) => state.userReducer.profile);

    useEffect(() => {
        const filterActive = menuList.filter((item: any) => {
            return item.tag === activeMenu;
        });
        setBreadcrumbActiveMenu(filterActive[0]);
    }, []);

    const showDrawer = () => {
        const drawer = document.getElementById('nav-drawer');
        drawer?.classList.add('visible');
    };

    return (
        <>
            <HeaderWrapper id="header">
                <HeaderItemWrapper>
                    <HeaderItem>
                        <MobileDrawerToggle onClick={() => showDrawer()}>
                            <Icon icon="menu" />
                        </MobileDrawerToggle>
                        {activeMenu && (
                            <ActiveMenu>
                                <Icon custom icon={breadcrumbActiveMenu.icon} />
                                <span>{breadcrumbActiveMenu.title}</span>
                            </ActiveMenu>
                        )}
                    </HeaderItem>
                    <HeaderItem alignRight>
                        <div className="row justify-content-end align-items-center">
                            {/* <div className="col-2">
                                <ButtonIcon>
                                    <Icon icon="sun" />
                                </ButtonIcon>
                            </div> */}
                            <div className="col-7">
                                <div className="row">
                                    <div className="col-12">
                                        <p className="p-0 m-0 text-start font-bold text-nowrap text-truncate">{company_name}</p>
                                    </div>
                                    <div className="col-12">
                                        <p className="p-0 m-0 text-start font-small text-nowrap text-capitalize text-truncate">{`${name} - ${role_name.toLowerCase()}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                                <ProfileWrapper onClick={() => setShowProfile(!showProfile)}>
                                    <Icon custom icon={iconProfile} />
                                    <Icon icon="chevron-down" />
                                </ProfileWrapper>
                            </div>
                        </div>
                    </HeaderItem>
                </HeaderItemWrapper>
            </HeaderWrapper>
            <ProfileMenu visible={showProfile} onProfileClose={(showProfileMenu: boolean) => setShowProfile(showProfileMenu)} />
        </>
    );
};

Header.defaultProps = {
    activeMenu: ''
};

export default Header;
