import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import iconPeopleTeamAdd from '../../../assets/icon/icon-people-team-add.svg';
import iconWallet from '../../../assets/icon/icon-wallet.svg';
import iconLocation from '../../../assets/icon/icon-location-pin.svg';
import noImage from '../../../assets/img/no-image.png';
import useCurrency from '../../../helpers/useCurrency';
import useDate from '../../../helpers/useDate';
import { Button, Card } from '../../../components/Styled';
import DialogRejectPayment from './DialogRejectPayment';
import API from '../../../configs/api';
// import staticDetailPayment from '../@static/detail-payment';

const ItemProjectDetail = styled.div`
    position: relative;
    width: 100%;
    border-radius: 20px;
    background: #4E4E4E;
    margin: 25px 0;

    .item-project-header {
        position: relative;
        padding: 10px 20px;
        background: #2F2F2F;
        color: #FFF;
        border-radius: 20px 20px 0 0;
    }

    .item-project-body {
        position: relative;
        display: block;
        width: 100%;
        padding: 20px;

        .item-project-detail {
            position: relative;
            display: block;
            width: 100%;
            padding: 15px;
            background: var(--color-white);
            border-radius: 20px;

            img {
                filter: grayscale(100%);
                opacity: 70%;

            }

            #location img {
                filter: none;
                opacity: 100%;
            }
        }
    }
    
    .item-project-footer {
        position: relative;
        display: block;
        background: #686868;
        width: 100%;
        margin: 20px 0 0;
        padding: 15px 20px;
        color: #FFF;
        border-radius: 20px;

        .profile-img {
            width: 40px;
            height: 40px;
            margin: auto;

            img {
                height: 100%;
                border-radius: 100px;
            }
        }
    }
`;

const ItemWorkerDetail = styled.div`
    position: relative;
    display: block;
    width: 100%;
    margin: 25px 0;

    .profile-img {
        width: 40px;
        height: 40px;
        margin: auto;

        img {
            height: 100%;
            border-radius: 100px;
        }
    }
`;

const ReasonWrapper = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    padding: 10px 1.5rem;
    background: #F7F5F5;
    color: #959598;
    border-radius: 100px;

    span {
        color: var(--color-base-primary-light);
    }
`;

interface ISelectedPaymentItem {
    id: any,
    // eslint-disable-next-line no-unused-vars
    onDataTotalFetched: (total: number) => void,
    // eslint-disable-next-line no-unused-vars
    onRejectPayment: (payment_id: number, worker: any) => void
    // eslint-disable-next-line no-unused-vars
    onAcceptedPayment: (payment_id: number, worker: any) => void
}

const SelectedPaymentItem: React.FC<ISelectedPaymentItem> = (props) => {
    const { id, onDataTotalFetched, onAcceptedPayment, onRejectPayment } = props;
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const [data, setData] = useState<any>({});
    const [listItem, setListItem] = useState<any>([]);
    const [selectedRejectItem, setSelectedRejectItem] = useState<any>({ payment_id: 0, worker: {} });
    const [rejectedItem, setRejectedItem] = useState<any>([]);
    const [acceptedItem, setAcceptedItem] = useState<any>([]);

    const onRejectConfirm = (payment_id: number, worker: any, reason: string) => {
        const filterAcceptedItem = acceptedItem.filter((item: any) => item.user_id === worker.user_id);
        if (filterAcceptedItem.length > 0) {
            const updateAcceptedItem = acceptedItem.filter((item: any) => item.user_id !== worker.user_id);
            setAcceptedItem(updateAcceptedItem);
            const item = {
                user_id: worker.user_id,
                reason
            };
            onRejectPayment(payment_id, item);
            setRejectedItem([item, ...rejectedItem]);
            return;
        }

        const item = {
            user_id: worker.user_id,
            reason
        };
        onRejectPayment(payment_id, item);
        setRejectedItem([item, ...rejectedItem]);
    };

    const onAcceptedConfirm = (payment_id: number, worker: any) => {
        const item = {
            user_id: worker.user_id,
            amount: worker.amount
        };
        setAcceptedItem([item, ...acceptedItem]);
        onAcceptedPayment(payment_id, item);
    };

    const onRejectPaymentClick = (payment_id: number, worker: any) => {
        setSelectedRejectItem({ payment_id, worker });
        setShowRejectDialog(true);
    };

    const fetchData = () => {
        const payload = {
            query: { id }
        };
        API.paymentStatusPendingDetail(payload).then((result: any) => {
            setData(result.data[0]);
            setListItem(result.data[0].mandor);
            onDataTotalFetched(result.data[0].mandor.length);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const checkRejectedItem = (user_id: string) => {
        const filterItem = rejectedItem.filter((item: any) => item.user_id === user_id);
        return filterItem;
    };

    const checkAcceptedItem = (user_id: string) => {
        const filterItem = acceptedItem.filter((item: any) => item.user_id === user_id);
        return filterItem;
    };

    const onAcceptAllItem = () => {
        if (rejectedItem.length > 0) {
            return;
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < listItem.length; i++) {
            const item = {
                user_id: listItem[i].user_id,
                amount: listItem[i].amount
            };
            onAcceptedConfirm(data.id, item);
        }
    };

    return (
        <>
            <ItemProjectDetail>
                <div className="item-project-header">
                    <div className="row">
                        <div className="col-6">
                            <span>{`${useDate.getDay()}, ${useDate.getCurrentDate(true, true, true)} | ${useDate.getCurrentTime(true, true)}`}</span>
                        </div>
                        <div className="col-6 text-end">
                            <span>{data.invoice}</span>
                        </div>
                    </div>
                </div>
                <div className="item-project-body">
                    <div className="item-project-detail">
                        <div id="project-title">
                            <span className="font-large font-bold">{data.project_code}</span>
                            <span className="font-large"> | </span>
                            <span className="font-large">{data.project_name}</span>
                            <p>{data.client_name}</p>
                        </div>
                        <div className="row">
                            <div className="col-10">
                                <div id="project-detail">
                                    <p className="m-0">{data.address}</p>
                                    <p className="m-0">
                                        <span style={{ color: '#A5A5A6' }}>Catatan : </span>
                                        <span className="fst-italic" style={{ color: '#2F2F2F' }}>{data.note}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-2 text-end align-item-end">
                                <Button border primary rounded id="location">
                                    <Icon custom icon={iconLocation} />
                                    Lihat Peta
                                </Button>
                            </div>
                        </div>
                        <div className="lines my-2" />
                        <div className="row">
                            <div className="col-6 border-end">
                                <p className="mb-1">Anggota yang diajukan</p>
                                <div>
                                    <Icon custom icon={iconPeopleTeamAdd} />
                                    <span className="px-2 align-middle font-bold font-large">{data.total_workers}</span>
                                </div>
                            </div>
                            <div className="col-6 ps-5">
                                <p className="mb-1">Total Pengajuan</p>
                                <div>
                                    <Icon custom icon={iconWallet} />
                                    <span className="px-2 align-middle font-bold font-large">{`Rp${useCurrency(data.total_request)}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item-project-footer">
                        <div className="row">
                            <div className="col-6">
                                <div className="row my-2 justify-content-between">
                                    <div className="col-profile">
                                        <div className="profile-img">
                                            <img src={data.pm_profile_image} alt={data.pm_name} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <p className="mb-1">Project Manager (PM)</p>
                                        <p className="mb-1 font-bold">{data.pm_name}</p>
                                        <p className="m-0">{data.pm_id}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 text-end">
                                <Button rounded gradient>Lihat Profil</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ItemProjectDetail>
            <div className="row align-items-center">
                <div className="col-7">
                    <p className="mb-2 font-bold">Detail Pengajuan</p>
                    <p className="mb-0">Silakan lakukan konfirmasi untuk pengajuan dibawah ini untuk dapat melanjutkan</p>
                </div>
                <div className="col-5">
                    <div className="row">
                        <div className="col-6">
                            <Button block border rounded textBlack>{`Tandai Tolak Semua (${listItem.length})`}</Button>
                        </div>
                        <div className="col-6">
                            <Button block border rounded textBlack disabled={rejectedItem.length > 0} onClick={() => onAcceptAllItem()}>{`Tandai Setuju Semua (${listItem.length})`}</Button>
                        </div>
                    </div>
                </div>
            </div>
            {listItem && listItem.length > 0 && listItem.map((item: any) => (
                <React.Fragment key={item.user_id}>
                    <ItemWorkerDetail>
                        <Card shadow>
                            <div className="row justify-content-between">
                                <div className="col-8">
                                    <div className="row my-2 justify-content-between">
                                        <div className="col-profile">
                                            <div className="profile-img">
                                                {item.profile_image || item.pm_profile_image ? (
                                                    <img src={item.profile_image ? item.profile_image : item.pm_profile_image} alt={item.pm_name ? item.pm_name : item.name} />
                                                ) : (
                                                    <img src={noImage} alt="Kanggo" />
                                                )}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <p className="mb-1 font-bold">
                                                <span>{item.name}</span>
                                                <span className="text-primary"> Detail Profile</span>
                                            </p>
                                            <p className="mb-1">{item.user_id}</p>
                                            <p className="mb-0" style={{ color: '#959598' }}>{item.role}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <p className="mb-1">Pembayaran Upah</p>
                                    <p className="font-bold" style={{ color: '#EB4600' }}>{`Rp${useCurrency(item.amount)}`}</p>
                                    <div className="row">
                                        <div className="col-6">
                                            {checkRejectedItem(item.user_id).length < 1 && (
                                                <Button block border rounded bold textBlack onClick={() => onRejectPaymentClick(data.id, item)}>Tolak</Button>
                                            )}
                                            {checkRejectedItem(item.user_id).length > 0 && (
                                                <Button block border rounded error bold>Tolak</Button>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            {checkRejectedItem(item.user_id).length < 1 && checkAcceptedItem(item.user_id).length < 1 && (
                                                <Button block border rounded bold textBlack onClick={() => (checkRejectedItem(item.user_id).length > 0 ? {} : onAcceptedConfirm(data.id, item))}>Setuju</Button>
                                            )}
                                            {checkRejectedItem(item.user_id).length > 0 && (
                                                <Button block border rounded bold textBlack>Setuju</Button>
                                            )}
                                            {checkAcceptedItem(item.user_id).length > 0 && (
                                                <Button block rounded active bold>Setuju</Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {checkRejectedItem(item.user_id).length > 0 && (
                                    <div className="col-12 mt-3">
                                        <ReasonWrapper>
                                            <p className="m-0">Alasan Penolakan</p>
                                            <span>{checkRejectedItem(item.user_id)[0].reason}</span>
                                        </ReasonWrapper>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </ItemWorkerDetail>
                    {showRejectDialog && item.user_id === selectedRejectItem.worker.user_id && (
                        <DialogRejectPayment id={data.id} onRejectPayment={(reason: string) => onRejectConfirm(selectedRejectItem.payment_id, selectedRejectItem.worker, reason)} dialogHandler={(showDialog: boolean) => setShowRejectDialog(showDialog)} data={item} />
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export default SelectedPaymentItem;
