/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Drawer from '../../../components/Drawer';
import Icon from '../../../components/Icon';
import DrawerPaymentItemItem from './DrawerPaymentActionItem';
import { Button, ButtonIcon } from '../../../components/Styled';
import iconWallet from '../../../assets/icon/icon-wallet-white.svg';
import useCurrency from '../../../helpers/useCurrency';
import DialogDeleteTransaction from './DialogDeleteTransaction';
import API from '../../../configs/api';
import MatchingPinDialog from '../../../components/MatchingPinDialog';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import action from '../../../configs/redux/action';

const DrawerHeader = styled.div`
    position: sticky;
    display: block;
    width: 100%;
    height: auto;
    top: 0;
    padding: .75rem 1.5rem;
    background: #F7F5F5;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, .07);
    z-index: 1;

    button {
        background: #F7F5F5;
        border-color: #D8CFCD;
    }
`;

const DrawerBody = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: .75rem 0;

    #item {
        position: relative;
        padding: 0 1.5rem;
    }
`;

const FloatingPayment = styled.div`
    position: sticky;
    display: block;
    width: 90%;
    height: auto;
    padding: 10px 0;
    margin: 0 auto;
    color: var(--color-white);
    background: #2F2F2F;
    border-radius: 100px;
    bottom: 20px;
    opacity: 100%;
    transition: opacity .3s ease;
    z-index: 99;
`;

interface IDrawerPayment {
    data: any[],
    onDeleteData: () => void,
    // eslint-disable-next-line no-unused-vars
    drawerHandler: (showDrawer: boolean) => void
}

const DrawerPayment: React.FC<IDrawerPayment> = (props) => {
    const { data, onDeleteData, drawerHandler } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showPinDialog, setShowPinDialog] = useState(false);
    const [acceptedProjects, setAcceptedProjects] = useState<any>([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [isPinMatch, setPinMatch] = useState(false);
    const [pin, setPin] = useState('');
    const [staticClose, setStaticClose] = useState(false);
    const [totalRespondedPayment, setTotalRespondedPayment] = useState(0);
    const [totalItemPayment, setTotalItemPayment] = useState(0);
    const [feeAdmin, setFeeAdmin] = useState(0);

    const onDeleteConfirm = () => {
        onDeleteData();
        drawerHandler(false);
    };

    const onWorkerRejected = (project_id: number, worker: any) => {
        const existingAcceptedProjects = acceptedProjects.filter((project: any) => {
            return project.id === project_id;
        });
        if (existingAcceptedProjects.length > 0) {
            const currentAcceptedProjects = acceptedProjects.filter((project: any) => project.id !== project_id);
            const currentAcceptedMandor = existingAcceptedProjects[0].approved_mandors.filter((mandor: any) => {
                return worker.user_id === mandor.mandor_id;
            });
            if (currentAcceptedMandor.length > 0) {
                const newAcceptedMandor = currentAcceptedMandor.filter((mandor: any) => {
                    return worker.user_id !== mandor.mandor_id;
                });
                const updateAcceptedProjects = {
                    id: project_id,
                    approved_mandors: newAcceptedMandor
                };
                setAcceptedProjects([updateAcceptedProjects, ...currentAcceptedProjects]);
            }
            // const currentAcceptedMandor = existingAcceptedProjects[0].approved_mandors;
            const newAcceptedMandor = currentAcceptedMandor.filter((mandor: any) => {
                return worker.user_id !== mandor.mandor_id;
            });
            const updateAcceptedProjects = {
                id: project_id,
                approved_mandors: newAcceptedMandor
            };
            setAcceptedProjects([updateAcceptedProjects, ...currentAcceptedProjects]);
            return;
        }
        setTotalRespondedPayment(totalRespondedPayment + 1);
    };

    const onWorkerAccepted = (project_id: number, worker: any) => {
        const existingAcceptedProjects = acceptedProjects.filter((project: any) => {
            return project.id === project_id;
        });
        if (existingAcceptedProjects.length > 0) {
            const currentAcceptedProjects = acceptedProjects.filter((project: any) => project.id !== project_id);
            const currentAcceptedMandor = existingAcceptedProjects[0].approved_mandors;
            const updateAcceptedProjects = {
                id: project_id,
                approved_mandors: [
                    {
                        mandor_id: worker.user_id,
                        amount: parseInt(worker.amount, 10)
                    },
                    ...currentAcceptedMandor
                ]
            };
            setAcceptedProjects([updateAcceptedProjects, ...currentAcceptedProjects]);
            setTotalRespondedPayment(totalRespondedPayment + 1);
            return;
        }
        const newAcceptedProject = {
            id: project_id,
            approved_mandors: [
                {
                    mandor_id: worker.user_id,
                    amount: parseInt(worker.amount, 10)
                }
            ]
        };
        setAcceptedProjects([newAcceptedProject, ...acceptedProjects]);
        setTotalRespondedPayment(totalRespondedPayment + 1);
    };

    useEffect(() => {
        let amount = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < acceptedProjects.length; i++) {
            // eslint-disable-next-line no-plusplus
            for (let i2 = 0; i2 < acceptedProjects[i].approved_mandors.length; i2++) {
                amount += acceptedProjects[i].approved_mandors[i2].amount;
                setTotalPayment(amount + feeAdmin);
            }
        }
    }, [acceptedProjects]);

    useEffect(() => {
        API.feeAdmin().then((result: any) => {
            setFeeAdmin(result.data.amount);
        });
    }, []);

    const acceptPayment = () => {
        const payload = {
            body: {
                total_payment: totalPayment,
                projects: acceptedProjects
            }
        };
        dispatch(action.showLoading());
        API.acceptPayment(payload).then((result: any) => {
            // useLocalStorage.set('payment_link', result.data.payment_link);
            setTimeout(() => {
                navigate(`/status-pembayaran/bayar/${result.data.external_id}`);
            }, 1000);
        }).catch(() => {
            dispatch(action.hideLoading());
        });
    };

    const matchingPin = () => {
        if (pin.length < 1) {
            setShowPinDialog(true);
            return;
        }
        const payload = {
            body: {
                pin
            }
        };
        dispatch(action.showLoading());
        setShowPinDialog(false);
        API.matchPin(payload).then(() => {
            setPinMatch(true);
            setTimeout(() => {
                acceptPayment();
                dispatch(action.hideLoading());
                setPin('');
            }, 1000);
        }).catch(() => {
            setPinMatch(false);
            dispatch(action.showToast('error', 'Pin tidak cocok!'));
            setTimeout(() => {
                dispatch(action.hideLoading());
                setPin('');
                setShowPinDialog(true);
            }, 1000);
        });
    };

    useEffect(() => {
        if (pin.length > 0) {
            matchingPin();
        }
    }, [pin]);

    const handleSubmit = () => {
        if (isPinMatch) {
            acceptPayment();
            return;
        }
        matchingPin();
    };

    return (
        <Drawer noPadding drawerHandler={drawerHandler} staticClose={staticClose} closeIcon={false} right>
            <DrawerHeader>
                <div className="row align-items-center">
                    <div className="col-8">
                        <div className="row align-items-center">
                            <div className="col-profile">
                                <div className="profile-img">
                                    <img src="https://storage.googleapis.com/kang-go.appspot.com/kanggo-kontraktor/profiles/1658916478437.jpg?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=UEU%2BFZL5SffRT3u4DJTBWq3W9SfNdB95UHSedehXbq9lU4oVluh0ra6HpEF3NLwM7sFURvTFHL2sT%2FuZrXQC9ToGN7e4TzX%2FpE0ZfLNuZqXfQ9eWwYX0s8vOIrEtuoNNbtgvhuiwh5eB1nGSDNdk2WmFCznAyhGycQWAT%2FmkJNy7hlE4U%2BbntGGi9HZV2Z6gQ7K5xiGd%2B1ln6qFlG3z8%2B5p%2BIWK0cEFXv3c5c9j5jAwf%2BvVfiLUvvkDSzcElQrH9G8XxZZOwbwSL6%2B7Td3XB2pHIhEwzUPAJ%2Fr4e9H0eDPeWbXYYphVACfe%2BbhgjE4EH3gThJ3mNDbG6H0Wsy%2FimmA%3D%3D" alt="Profile" />
                                </div>
                            </div>
                            <div className="col-10">
                                <p className="mb-0">Total transaksi yang dipilih</p>
                                <p className="mb-0 font-bold font-large">{data.length}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 text-end">
                        <Button border rounded textBlack onClick={() => setShowDeleteDialog(true)}>Hapus Semua Yang Ditandai</Button>
                    </div>
                    <div className="col-1 text-end">
                        <ButtonIcon onClick={() => setStaticClose(true)}>
                            <Icon icon="x" />
                        </ButtonIcon>
                    </div>
                </div>
            </DrawerHeader>
            <DrawerBody>
                {data.map((item) => (
                    // eslint-disable-next-line react/jsx-fragments
                    <React.Fragment key={item.id}>
                        <div id="item">
                            <DrawerPaymentItemItem id={item.id} onDataTotalFetched={(total: any) => setTotalItemPayment((t: any) => t + total)} onAcceptedPayment={(payment_id: number, worker: any) => onWorkerAccepted(payment_id, worker)} onRejectPayment={(payment_id: number, worker: any) => onWorkerRejected(payment_id, worker)} />
                        </div>
                        <div className="divider" />
                    </React.Fragment>
                ))}
            </DrawerBody>
            <FloatingPayment>
                <div className="row align-items-center px-3">
                    <div className="col-6">
                        <div className="row align-items-center">
                            <div className="col-profile">
                                <Icon custom large icon={iconWallet} />
                            </div>
                            <div className="col-6">
                                <p className="mb-0">Total yang dibayar</p>
                                <div className="mb-0 font-bold font-large">
                                    {`Rp${useCurrency(totalPayment)}`}
                                    <a className="ms-2" style={{ color: '#fff' }} data-tip={`Rp. ${useCurrency(feeAdmin)} adalah biaya admin.`}>
                                        <Icon icon="info" />
                                    </a>
                                    <ReactTooltip place="bottom" type="dark" effect="solid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-6">
                                <Button block border rounded style={{ background: 'transparent' }} onClick={() => setStaticClose(true)}>
                                    <Icon custom icon={iconWallet} />
                                    + Transaksi Lainnya
                                </Button>
                            </div>
                            <div className="col-6">
                                <Button block rounded disabled={acceptedProjects?.approved_mandors?.length < 1 || totalItemPayment !== totalRespondedPayment || totalPayment < 1} gradient onClick={() => handleSubmit()}>
                                    Bayar Transaksi
                                    <Icon icon="chevron-right" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </FloatingPayment>
            {showDeleteDialog && (
                <DialogDeleteTransaction dialogHandler={(showDialog: boolean) => setShowDeleteDialog(showDialog)} onDeleteConfirm={onDeleteConfirm} />
            )}
            {showPinDialog && (
                <MatchingPinDialog fee={feeAdmin} dialogHandler={(visible: boolean) => setShowPinDialog(visible)} onConfirm={(value: string) => setPin(value)} total={totalPayment} />
            )}
        </Drawer>
    );
};

export default DrawerPayment;
