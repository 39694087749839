import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

const DrawerWrapper = styled.div<{ left?: boolean, right?: boolean, visible?: boolean, noPadding?: boolean }>`
    position: fixed;
    display: block;
    width: 100%;
    max-width: calc(100% - 10%);
    height: 100%;
    background: var(--color-base-bg-light);
    padding: ${(props) => (props.noPadding ? '0' : '1.5rem')};
    top: 0;
    ${(props) => (props.left ? 'left: -120%;' : '')}
    ${(props) => (props.right ? 'right: -120%;' : '')}
    transition: left .2s ease, right .2s ease;
    border-right: 1px solid #EBE7E6;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 102;

    ${(props) => (props.visible && props.left ? 'left: 0;' : '')}
    ${(props) => (props.visible && props.right ? 'right: 0;' : '')}

    @media only screen and (max-width: 768px) {
        left: -120%;
        &.visible {
            max-width: 80%;
            left: 0;
        }

        &.minimize {
            max-width: 80% !important;
        }
    }
`;

const DrawerToggle = styled.div`
    position: absolute;
    display: flex;
    top: 1.5rem;
    right: 1.5rem;
    width: 26px;
    height: 26px;
    background: transparent;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    transition: .3s ease;
    z-index: 2;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, .06);
    }
`;

interface IDrawer {
    left?: boolean,
    right?: boolean,
    noPadding?: boolean,
    closeIcon?: boolean,
    staticClose?: boolean,
    // eslint-disable-next-line no-unused-vars
    drawerHandler: (showDrawer: boolean) => void
    children: React.ReactNode
}

const Drawer: React.FC<IDrawer> = (props) => {
    const { left, right, noPadding, closeIcon, staticClose, drawerHandler, children } = props;
    const [visible, setVisible] = useState(false);

    const onDrawerClose = () => {
        setVisible(false);
        setTimeout(() => {
            drawerHandler(false);
        }, 250);
    };

    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 250);
    }, []);

    useEffect(() => {
        if (staticClose === true) {
            onDrawerClose();
        }
    }, [staticClose]);

    return (
        <>
            <div id="overlay" />
            <DrawerWrapper left={left} right={right} visible={visible} noPadding={noPadding}>
                {closeIcon && (
                    <DrawerToggle onClick={() => onDrawerClose()}>
                        <Icon large icon="x" />
                    </DrawerToggle>
                )}
                {children}
            </DrawerWrapper>
        </>
    );
};

Drawer.defaultProps = {
    left: false,
    right: false,
    closeIcon: true,
    noPadding: false,
    staticClose: false
};

export default Drawer;
