import React from 'react';
import styled from 'styled-components';
import noDataImg from '../assets/img/illustrasi-no-data.png';

const NoDataWrapper = styled.div`
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
    height: auto;
    padding: 20px 0;

    img {
        height: 100%;
    }
`;

interface INoData {
    message: string,
    description?: string
}

const NoData: React.FC<INoData> = (props) => {
    const { message, description } = props;
    return (
        <NoDataWrapper>
            <img src={noDataImg} alt="No Data" />
            <p className="mb-0 mt-2 font-bold">{message}</p>
            {description ? (
                <p className="mb-0 mt-1">{description}</p>
            ) : null}
        </NoDataWrapper>
    );
};

NoData.defaultProps = {
    description: ''
};

export default NoData;
