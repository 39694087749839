import React from 'react';
import styled from 'styled-components';
import useDate from '../../helpers/useDate';
import Pagination from '../Pagination';

const ActivityDateWrapper = styled.div`
    min-width: 200px;
    padding-right: 1em;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    border-right: 1px solid #959598;

    @media screen and (max-width: 768px) {
        #activity-date-wrapper {
            border-right: none;
        }
    }
`;

export const ActivityWrapper = styled.div`
    margin: 0 -1.5em;
`;

interface IData {
    created_at: string,
    message_plain: string
}

interface IItemActivity {
    data: IData
}

const ItemActivity: React.FC<IItemActivity> = ({ data }) => {
    const { created_at, message_plain } = data;
    return (
        <div className="d-flex align-items-center py-4" style={{ borderBottom: '1px solid #D8CFCD' }}>
            <div className="d-flex w-100 mx-cs-2">
                <ActivityDateWrapper>
                    {created_at ? (
                        <>
                            <p className="mb-0 font-small">{useDate.isSameDate(created_at) ? useDate.isSameDate(created_at) : 'Tanggal Dibuat'}</p>
                            <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
                                <p className="mb-0 me-1 fw-bold">{useDate.getMomentDate(created_at, false, 'DD MMMM YYYY')}</p>
                                <p className="mb-0">{`| ${useDate.getHour(created_at)}`}</p>
                            </div>
                        </>
                    ) : '-'}
                </ActivityDateWrapper>
                <div className="ms-3 d-flex align-items-center">
                    <p className="mb-0">{message_plain}</p>
                </div>
            </div>
        </div>
    );
};

interface DataAct {
    total_data: number,
    total_page: number,
    data: any[]
}

interface IListActivity {
    data: DataAct,
    page: number,
    // eslint-disable-next-line no-unused-vars
    onPageChange: (event: any) => void
}

const ListActivity: React.FC<IListActivity> = (props) => {
    const { data, page, onPageChange } = props;

    return (
        <ActivityWrapper>
            <p className="mx-cs-2">{useDate.namedDate('2023-01-26 17:51:16', true)}</p>
            {data.total_data > 0 && data.data.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ItemActivity key={index} data={item} />
            ))}
            <div className="mx-cs-2">
                <Pagination
                    totalData={data.total_data}
                    currentPage={page}
                    pageCount={data.total_page}
                    onPageChange={onPageChange}
                />
            </div>
        </ActivityWrapper>
    );
};

export default ListActivity;
